import React from "react";
import { Col, useScreenClass } from "react-grid-system";

import withGlobalProviders from "../../../../General/withGlobalProviders";

import { styles } from "./utils";
import ActivateButton from "./CTAColumn/ActivateButton";

function CTAColumn({ id }) {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const classes = styles();


  return (
    <Col
      xs={12}
      className={classes.ctaColumn}
      style={{
        maxWidth: isDesktop ? "160px" : "100%",
        width: isDesktop ? "160px" : "100%",
        flex: isDesktop ? "0 0 160px" : "0 0 100%",
        justifyContent: isDesktop ? "flex-start" : "center",
        alignItems: "center",
        flexDirection: isDesktop ? "column" : "row",
      }}
    >
      <a
        style={{
          height: 34,
          display: "block",
          float: "right",
          width: isDesktop ? "100%" : 160,
          marginBottom: isDesktop ? 12 : 0,
          marginRight: isDesktop ? 0 : 12,
        }}
        className="btn btn-sm btn-brand-secondary"
        href={"/" + id}
      >
        View
      </a>
      <ActivateButton {...{ id }} />
    </Col>
  );
}

export default withGlobalProviders(CTAColumn);
