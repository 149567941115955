import compact from "lodash/compact";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import React, { useEffect, useState } from "react";

import { GET } from "../../../../util/apiHelpers";
import useSharedStyles from "../../../Individuals/show/useSharedStyles";

import Review from "./Reviews/Review";

export default function Reviews({
  individualId,
  endpoint,
  title,
  publicEndpoint,
  rfiLiveLink,
}) {
  const sharedClasses = useSharedStyles();

  const [reviews, setReviews] = useState(null);
  const [loadStatus, setLoadStatus] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    GET(
      "public/api/v1/individuals/" + individualId + "/" + endpoint,
      rfiLiveLink ? { rfiLiveLink } : {}
    )
      .then((res) => {
        setReviews(res.data);
        setLoadStatus(true);
      })
      .catch(() => {
        setLoadStatus(true);
      });
  }, []);

  if (!reviews) return null;
  if (loadStatus && reviews.length === 0) return null;

  return (
    <div
      className={sharedClasses.section}
      style={title === "Candidate" ? { marginTop: 32 } : {}}
    >
      <div className={sharedClasses.sectionHeader}>
        {`Most Recent RecruitiFi ${title} Reviews`}
      </div>
      <div className={sharedClasses.sectionContent}>
        {map(
          sortBy(
            expanded ? reviews : compact([reviews[0], reviews[1]]),
            (review) => -review.created_at
          ),
          (review, idx) => (
            <Review
              key={review.id}
              review={review}
              idx={idx}
              rfiLiveLink={rfiLiveLink}
            />
          )
        )}
        {reviews.length > 2 &&
          (expanded ? (
            <div
              style={{
                color: "#f25b5e",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={() => setExpanded(false)}
            >
              See Less Reviews
            </div>
          ) : (
            <div
              style={{
                color: "#f25b5e",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={() => setExpanded(true)}
            >
              See More Reviews
            </div>
          ))}
      </div>
    </div>
  );
}
