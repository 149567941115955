import React from "react";
import { useSelector } from "react-redux";
import { useScreenClass } from "react-grid-system";

import makeStyles from "@material-ui/core/styles/makeStyles";

import useSharedStyles from "./useSharedStyles";
import AvatarIcon from "../../General/AvatarIcon";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 12
  },
  textSection: {
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
  agencyName: ({ isDesktop }) => ({
    fontSize: isDesktop ? 18 : 14,
  }),
}));

const Header = ({ individualId }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const individual = useSelector(state => state.individuals.items[individualId]);
  const organization = useSelector(
    (state) => state.organizations.items[individual.attributes.organizationId]
  );

  const classes = useStyles({ isDesktop });
  const sharedClasses = useSharedStyles({ isDesktop });

  const { firstName, lastName } = individual.attributes;

  return (
    <div className={classes.container}>
      <AvatarIcon member={individual} height={isDesktop ? 100 : 80} />
      <div className={classes.textSection}>
        <div className={sharedClasses.sectionHeader}>
          {firstName} {lastName}
        </div>
        <div className={classes.agencyName}>{organization.attributes.name}</div>
      </div>
    </div>
  );
};

export default Header;
