import React, { useContext } from "react";
import { createUseStyles } from "react-jss";

import { DirectoryContext } from "../IndexComponent";

const useStyles = createUseStyles({
  container: {
    padding: 20,
    textAlign: "center",
  },
  content: {
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Poppins",
    lineHeight: "2.28571rem",
    color: "#292E33",
  },
  action: {
    fontSize: "14px",
    fontWeight: "300",
    fontFamily: "Poppins",
    lineHeight: "2.28571rem",
    color: "#357BA6",
    cursor: "pointer",
  },
});

export default function NoResults() {
  const classes = useStyles();

  const { setSearch, resetFilters } = useContext(DirectoryContext);

  const showAll = () => {
    setSearch("");
    resetFilters();
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        We cannot find any recruiters or agencies with that information.
      </div>
      <div>
        <span className={classes.action} onClick={showAll}>
          Show all Agency Recruiters
        </span>
      </div>
    </div>
  );
}
