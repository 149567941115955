import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";

import map from "lodash/map";

import NoResults from "./items/NoResults";
import Skeletons from "./items/Skeletons";

import Item from "./Item";

const useStyles = createUseStyles({
  unorderedList: {
    margin: "1rem 0 1rem 0",
    padding: 0,
    listStyleType: "none",
    "& li+li": {
      borderTop: "1px solid #ECEDF2",
    },
  },
});

export default function Items({
  individuals,
  loading,
  currentPage,
  handlePageSelection,
}) {
  useEffect(() => {
    if ((individuals || []).length === 0) {
      if (currentPage && currentPage > 1) {
        handlePageSelection(1);
      }
    }
  }, [loading, individuals, currentPage]);

  const classes = useStyles();

  if (loading) return <Skeletons />;

  if ((individuals || []).length === 0) return <NoResults />;

  return (
    <ul className={classes.unorderedList}>
      {map(individuals, ({ id }) => (
        <Item {...{ key: id, id }} />
      ))}
    </ul>
  );
}
