import React from "react";
import { useSelector } from "react-redux";

import TruncatableContent from "./partials/TruncatableContent";
import useSharedStyles from "../show/useSharedStyles";

const RecruiterDescription = ({ individualId }) => {
  const individual = useSelector(
    (state) => state.individuals.items[individualId]
  );
  const sharedClasses = useSharedStyles();

  const { firstName, selfDescription } = individual.attributes;

  return (
    <div className={sharedClasses.section}>
      <div className={sharedClasses.sectionHeader}>About {firstName ? firstName : "the Recruiter"}</div>
      <div className={sharedClasses.sectionContent} style={{whiteSpace: "pre-line"}}>
        <TruncatableContent truncateHeight={160}>
          {selfDescription}
        </TruncatableContent>
      </div>
    </div>
  );
};

export default RecruiterDescription;
