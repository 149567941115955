import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ScreenClassProvider } from "react-grid-system";
import momentConverter from "@date-io/moment";

import configureStore from "../../configureStore";
import muiTheme from "./muiTheme";

import Snackbar from "./Snackbar";
import Modal from "./ActionModal/ConnectedModal";

const store = configureStore();

const withGlobalProviders = (Component) => (props) => (
  <Provider store={store}>
    <BrowserRouter>
      <ScreenClassProvider>
        <ThemeProvider theme={muiTheme}>
          <MuiPickersUtilsProvider utils={momentConverter}>
            <Component {...props} />
            <Snackbar />
            <Modal />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ScreenClassProvider>
    </BrowserRouter>
  </Provider>
);

export default withGlobalProviders;
