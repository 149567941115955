import React from "react";
import { Formik } from "formik";
import map from "lodash/map";
import queryString from "query-string";

// import RFITag from "../../../Individuals/show/partials/RFITag";
import ConnectedSelect  from "../../../General/FormInputs/NativeSelect";
import { CANDIDATE_INDUSTRY_OPTIONS, CANDIDATE_ROLE_OPTIONS, USA_LOCATION_OPTIONS } from "../../../Settings/shared/constants";
import RFITag from "../shared/partials/RFITag";

function generateOptions(array) {
  return map(array, (item) => ({ value: item, label: item }));
};

const roleOptions = generateOptions(CANDIDATE_ROLE_OPTIONS);
const industryOptions = generateOptions(CANDIDATE_INDUSTRY_OPTIONS);
const locationOptions = generateOptions(USA_LOCATION_OPTIONS);

const initialValues = {
  role: "",
  industry: "",
  location: ""
};

export default function Hero() {
  const formSubmit = (values) => {
    const query = queryString.stringify({
      industries: values.industry === "" ? [] : values.industry,
      roles: values.role === "" ? [] : values.role,
      locations: values.location === "" ? [] : values.location,
      order: "desc_rating"
    })

    location.href = "/search?" + query
  }

  return (
    <div className="bg-astral pt-32 pb-16">
      <h1 className="text-center tracking-wide text-5xl font-semibold text-gray-100">Agency Recruiter Directory</h1>
      <div className="flex justify-center mt-2">
        <RFITag light={true} profile={false} small={true} />
      </div>
      <div className="kt-container mt-14">
        <h2 className="text-2xl md:text-3xl text-left font-medium text-gray-100 tracking-normal ml-12 md:ml-0">
          Find the best expert recruiters for your hard-to-fill roles
        </h2>
        <div className="mx-12 md:mx-0 mt-2">
          <Formik
            initialValues={initialValues}
            onSubmit={formSubmit}
          >
            {({ handleSubmit }) => (
              <div className="max-w-full w-full text-center rounded-lg shadow-xl p-4 bg-white grid grid-cols-1 md:grid-cols-4 lg:grid-cols-7 gap-4">
                <div className="col-span-2 text-left">
                  <ConnectedSelect tabIndex="1" removeFormGroupMargin={true} id="role" placeholder="Candidate Role" options={roleOptions} />
                </div>
                <div className="col-span-2 text-left">
                  <ConnectedSelect tabIndex="2" removeFormGroupMargin={true} id="industry" placeholder="Company Industry" options={industryOptions} />
                </div>
                <div className="col-span-2 text-left">
                  <ConnectedSelect tabIndex="3" removeFormGroupMargin={true} id="location" placeholder="Job Location" options={locationOptions} />
                </div>
                <div className="col-span-1 md:grid-cols-2 lg:grid-cols-1 text-left flex items-end justify-end">
                  <button onClick={handleSubmit} type="submit" tabIndex="4" className="btn btn-brand-secondary w-full focus:bg-astral-600" style={{ height: "3.18rem", width: "100%" }}>Quick Search</button>
                </div>
              </div>
            )}
          </Formik>
        </div>
        <div className="mr-12 md:mr-0 mt-2 text-lg font-medium text-right">
          <a href="/search" className="text-gray-100 underline hover:text-astral-50">or search using more criteria</a>
        </div>
      </div>
      <div className="text-center mt-10">
        <a className="text-white font-medium text-3xl underline hover:text-gray-100" href="/for-agencies">List your Agency on TALK</a>
      </div>
    </div>
  )
}
