import React from "react";
import { LOADED } from "./Index";
import { RatingDisplay } from "./RatingDisplay";

export function RecentRatings({
  loadStatus,
  sharedClasses,
  employerRatingsData,
}) {
  const { overall: employerOverall, numRatees: employerNumRatees } =
    employerRatingsData;

  return (
    loadStatus === LOADED &&
    !!employerNumRatees &&
    employerNumRatees > 0 && (
      <>
        <h5 className={sharedClasses.subSectionHeader}>
          Ratings from Employers
        </h5>
        <div className={sharedClasses.sectionContent}>
          <>
            <RatingDisplay
              title={<strong style={{ color: "#48465B" }}>Overall</strong>}
              rating={employerOverall}
              numRatees={employerNumRatees}
            />
          </>
        </div>
      </>
    )
  );
}
