import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Row, Col, Visible, useScreenClass } from "react-grid-system";
import round from "lodash/round";

import { styles, Name, OrganizationName } from "./utils";
import StarRatingDisplay from "../../../../Vendors/general/StarRatingDisplay";

export default function InfoColumn({ id }) {
  const individual = useSelector(
    (state) => state.individuals.items[id],
    shallowEqual
  );

  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const classes = styles();

  const { firstName, lastName, overallRating } = individual.attributes;

  return (
    <Col
      xs={12}
      lg={8}
      className={classes.infoColumn}
      style={{
        width: isDesktop ? "calc(100% - 280px)" : "100%",
        maxWidth: isDesktop ? "calc(100% - 280px)" : "100%",
        flex: isDesktop ? "0 0 calc(100% - 280px)" : "0 0 100%",
        marginTop: isDesktop ? 0 : 20,
        marginBottom: isDesktop ? 0 : 20,
      }}
    >
      <Row>
        <Visible lg xl xxl>
          <Col xs={12}>
            <Name {...{ firstName, lastName, id }} />
            <br />
            <OrganizationName individualName={firstName + " " + lastName} />
            <br />
            <StarRatingDisplay
              small={true}
              rating={overallRating > 0 ? round(overallRating * 2, 0) / 2 : 0}
              customInfo={
                overallRating > 0
                  ? ` (${round(overallRating, 2)})`
                  : " 0 reviews"
              }
            />
          </Col>
        </Visible>
      </Row>
    </Col>
  );
}
