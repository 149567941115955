import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Chip from "@material-ui/core/Chip";

import map from "lodash/map";
import TruncatableContent from "../../../Public/Individuals/shared/partials/TruncatableContent";
import useSharedStyles from "../useSharedStyles";

const useStyles = makeStyles((theme) => ({
  sectionContainer: ({ isDesktop }) => ({
    padding: isDesktop ? 18 : "18px 0",
  }),
  chipItem: ({ chipColor }) => ({
    margin: theme.spacing(0.5),
    backgroundColor: chipColor,
    fontSize: 14,
  }),
  chipLabel: {
    fontWeight: 400,
  },
  chipList: {
    padding: "8px 8px 8px 16px",
  },
}));

const ChipItem = ({ label, chipColor, isDesktop }) => {
  const classes = useStyles({ chipColor, isDesktop });

  return (
    <Chip
      label={label}
      size="medium"
      classes={{ root: classes.chipItem, label: classes.chipLabel }}
      variant="outlined"
    />
  );
};

const SearchSpecialtiesSection = ({
  title,
  itemLabels,
  chipColor,
  isDesktop,
}) => {
  const sharedClasses = useSharedStyles({ isDesktop });
  const classes = useStyles();

  if (itemLabels && itemLabels.length === 0) return null;

  return (
    <div className={classes.sectionContainer}>
      <h5 className={sharedClasses.subSectionHeader}>{title}</h5>
      <div className={classes.chipList}>
        <TruncatableContent truncateHeight={100}>
          {map(itemLabels, (label) => (
            <ChipItem key={label} label={label} chipColor={chipColor} />
          ))}
        </TruncatableContent>
      </div>
    </div>
  );
};

export default SearchSpecialtiesSection;
