import React from "react";

import useSharedStyles from "../useSharedStyles";
import CandidateProgression from "./CandidateProgression";
import CandidateRatings from "./CandidateRatings";

const Statistics = ({ individualId }) => {
  const sharedClasses = useSharedStyles();

  return (
    <div className={sharedClasses.section}>
      <div className={sharedClasses.sectionHeader}>
        <img
          style={{ display: "inline", height: 42, marginRight: 12 }}
          src={
            "https://recruitifi-public.s3.amazonaws.com/default_avatars/rfi_individual_square.png"
          }
          alt="RecruitiFi-logo"
        />
        RecruitiFi Statistics
      </div>
      <div className={sharedClasses.sectionContent}>
        <CandidateRatings {...{ individualId }} />
        {/* <CandidateProgression {...{ individualId }} /> */}
      </div>
    </div>
  );
};

export default Statistics;
