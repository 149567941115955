import React from "react";
import { useSelector, shallowEqual } from "react-redux";

import { styles } from "./item/utils";
import AvatarColumn from "./item/AvatarColumn";
import InfoColumn from "./item/InfoColumn";
import AttributesRow from "./item/AttributesRow";

export default function Item({ id }) {
  const individual = useSelector(
    (state) => state.individuals.items[id],
    shallowEqual
  );

  if (!individual) return null;

  const classes = styles({});

  return (
    <li>
      <div className={classes.itemContainer}>
        <AvatarColumn {...{ id }} />
        <InfoColumn {...{ id }} />
      </div>
      <div>
        <AttributesRow {...{ id }} />
      </div>
    </li>
  );
}
