import React, { useEffect, useState } from "react";
import { useScreenClass } from "react-grid-system";

import every from "lodash/every";
import values from "lodash/values";

import useSharedStyles from "./useSharedStyles";
import { GET } from "../../../util/apiHelpers";

const LOADING = "LOADING";
const LOADED = "LOADED";
const ERROR = "ERROR";

const HiredStatistics = ({ individualId }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const sharedClasses = useSharedStyles({ isDesktop });

  const [progressionStats, setProgressionStats] = useState(null);
  const [loadStatus, setLoadStatus] = useState(LOADING);

  useEffect(() => {
    GET(`public/api/v1/individuals/${individualId}/aggregate_progression_stats`)
      .then((res) => {
        setProgressionStats(res.data);
        setLoadStatus(LOADED);
      })
      .catch(() => {
        setLoadStatus(ERROR);
      });
  }, []);

  if (loadStatus !== LOADED || every(values(progressionStats), (val) => !val))
    return null;

  if (!(progressionStats.hires > 0)) return null;
  return (
    <div className={sharedClasses.rightSection}>
      <div className={sharedClasses.rightSectionHeader}>
        RecruitiFi Statistics
      </div>
      <div className={sharedClasses.sectionContent}>
        Verified hires on RecruitiFi
      </div>
    </div>
  );
};

export default HiredStatistics;
