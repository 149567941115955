import map from "lodash/map";

import {
  USA_LOCATION_OPTIONS,
  INTERNATIONAL_LOCATION_OPTIONS,
  CANDIDATE_INDUSTRY_OPTIONS,
  CANDIDATE_LEVEL_OPTIONS,
  CANDIDATE_ROLE_OPTIONS,
  DIVERSITY_FOCUS_OPTIONS,
} from "../../../Settings/shared/constants";

const locationFilters = {
  id: "locations",
  title: "Locations: USA",
  options: map(USA_LOCATION_OPTIONS, (option) => ({
    label: option,
    value: option,
    color: "rgb(236, 242, 239)",
  })),
  searchable: true,
  color: "rgb(236, 242, 239)",
};

const internationalLocationFilters = {
  id: "international_locations",
  title: "Locations: International",
  options: map(INTERNATIONAL_LOCATION_OPTIONS, (option) => ({
    label: option,
    value: option,
    color: "rgb(236, 242, 239)",
  })),
  searchable: true,
  color: "rgb(236, 242, 239)",
};

const industryFilters = {
  id: "industries",
  title: "Industry",
  options: map(CANDIDATE_INDUSTRY_OPTIONS, (option) => {
    if (option.title) return option;
    return {
      label: option,
      value: option,
      color: "rgb(241, 236, 244)",
    };
  }),
  searchable: true,
  color: "rgb(241, 236, 244)",
};

const levelFilters = {
  id: "levels",
  title: "Candidate Level",
  options: map(CANDIDATE_LEVEL_OPTIONS, (option) => ({
    label: option,
    value: option,
    color: "rgb(258, 248, 240)",
  })),
  color: "rgb(258, 248, 240)",
};

const roleFilters = {
  id: "roles",
  title: "Functional Role",
  options: map(CANDIDATE_ROLE_OPTIONS, (option) => ({
    label: option,
    value: option,
    color: "rgb(237, 247, 251)",
  })),
  color: "rgb(237, 247, 251)",
};

const diversityFocusFilters = {
  id: "diversity_focus",
  title: "Diversity Focus",
  options: map(DIVERSITY_FOCUS_OPTIONS, (option) => ({
    label: option,
    value: option,
    color: "rgb(244, 236, 236)",
  })),
  color: "rgb(244, 236, 236)",
};

export function createFilters() {
  return [
    industryFilters,
    roleFilters,
    levelFilters,
    locationFilters,
    internationalLocationFilters,
    diversityFocusFilters,
  ];
}

export function createInitialState(filterSettings) {
  const initialState = {};

  filterSettings.forEach((filter) => {
    filter.options.forEach(
      ({ value, label }) =>
      (initialState[value + "|" + filterSettings.color] = {
        label,
        value: false,
      })
    );
  });

  return initialState;
}

export function updateFiltersHelper(state, filterSettings, callback) {
  const newFilters = {};
  filterSettings.forEach((filterSetting) => {
    const newTypeFilters = [];
    filterSetting.options.forEach(({ value, color }) => {
      if (
        (state[value] && state[value]["value"] && state[value]["color"] === color) ||
        (state[value + "|" + color] && state[value + "|" + color]["value"])
        ) {
        newTypeFilters.push(value);
      }
    });

    if (newTypeFilters.length > 0) {
      newFilters[filterSetting.id] = newTypeFilters.join("|");
    }
  });

  callback(newFilters);
}
