import React from "react";
import PropTypes from "prop-types";

import orderBy from "lodash/orderBy";
import moment from "moment";

import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  historyContainer: {
    display: "flex",
    marginTop: "20px",
    "@media only screen and (max-width: 950px)": {
      flexDirection: "column",
    },
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    width: 112,
    marginRight: 24,
    marginBottom: 12,
  },
  logo: {
    maxHeight: 50,
    maxWidth: 112,
    objectFit: "contain",
  },
  fieldDisplay: {
    fontSize: 16,
    color: "37474F",
    fontWeight: "regular",
    marginBottom: "5px",
    marginLeft: "20px",
    marginRight: "50px",
    width: "225px",
  },
  companyName: {
    fontWeight: 500,
  },
});

const WorkHistoryLogo = ({ logoUrl }) => {
  const classes = useStyles();
  return (
    <div className={classes.logoContainer}>
      <img
        className={classes.logo}
        src={logoUrl || "/assets/media/misc/city.png"}
      />
    </div>
  );
};

const EmployeeWorkHistoryAttribute = ({ label, value }) => (
  <div>
    <div name={label} />
    {value}
  </div>
);

export const EmployeeWorkHistories = ({ items }) => {
  const classes = useStyles();

  const renderItem = (item) => (
    <div className={classes.historyContainer}>
      <div data-cy={`emp-hist-avatar-field`}>
        <div>
          <WorkHistoryLogo logoUrl={item.avatar} />
        </div>
      </div>
      <div>
        <div className={`${classes.fieldDisplay} ${classes.companyName}`}>
          <EmployeeWorkHistoryAttribute
            label="Company"
            value={item.companyName}
          />{" "}
        </div>

        <div className={classes.fieldDisplay}>
          <EmployeeWorkHistoryAttribute
            label="Job Title"
            value={item.jobTitle}
          />{" "}
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <div className={classes.fieldDisplay}>
          <EmployeeWorkHistoryAttribute
            label="Start"
            value={moment(item.startDate).format("MMM YYYY")}
            className={"startAt"}
          />{" "}
        </div>
        {item.currentJob === false ? (
          <div className={classes.fieldDisplay}>
            <EmployeeWorkHistoryAttribute
              label="End"
              value={moment(item.endDate).format("MMM YYYY")}
              className={"endAt"}
            />{" "}
          </div>
        ) : (
          <div className={classes.fieldDisplay}>
            <EmployeeWorkHistoryAttribute
              label="End"
              value="Present"
              className={"endAt"}
            />
          </div>
        )}{" "}
      </div>
    </div>
  );

  return (
    <div>
      {orderBy(items, [
        (item) => moment().diff(moment(item.startDate)),
      ]).map((item) => renderItem(item))}
    </div>
  );
};

EmployeeWorkHistories.propTypes = {
  items: PropTypes.array.isRequired,
};

export default EmployeeWorkHistories;
