import React from "react";
import "tailwindcss/tailwind.css"

import Hero from "./Root/Hero";
import RecruiterCardCarousel from "./Root/RecruiterCardCarousel";
import LocationOfferings from "./Root/LocationOfferings";
import RecruiterCTA from "./Root/RecruiterCTA";
import Explainer from "./Root/Explainer";
import FrequentlyAskedQuestions from "./Root/FrequentlyAskedQuestions";

export default function Root({ highly_rated_recruiters, recruiters_with_recent_placements, recruitifiRegistrationUrl}) {
  return (
    <>
      <Hero />
      <RecruiterCardCarousel
        first={true}
        title="Highly Rated Agency Recruiters"
        subtitle="The highest rated agency recruiters based on ratings from Employers and Candidates."
        recruiters={JSON.parse(highly_rated_recruiters)}
      />
      <RecruiterCardCarousel
        title="Agency Recruiters with Recent Placements"
        subtitle={<span>Agency recruiters with verified placements via <a className="underline hover:text-astral-500" href="https://www.recruitifi.com" target="_blank" rel="noopener noreferrer">RecruitiFi</a>.</span>}
        recruiters={JSON.parse(recruiters_with_recent_placements)}
      />
      <Explainer />
      <LocationOfferings />
      <FrequentlyAskedQuestions />
      <RecruiterCTA {...{ recruitifiRegistrationUrl }}/>
    </>
  )
}
