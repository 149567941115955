import normalize from "json-api-normalizer";
import queryString from "query-string";
import { batch } from "react-redux";

import { GET } from "../util/apiHelpers";

import { mapKeysToSnakeCase, mapKeysToCamelCase } from "../util/formatHelpers";

import { receiveOrganizations } from "./organizationActions";

export const RECEIVE_INDIVIDUALS = "RECEIVE_INDIVIDUALS";

const receiveIndividuals = (individuals) => ({
  type: RECEIVE_INDIVIDUALS,
  payload: individuals,
});

export const fetchIndividuals = (params, isPublic = false) => (dispatch) => {
  const paramsStr = queryString.stringify(mapKeysToSnakeCase(params));

  return GET("/public/api/v1/individuals?" + paramsStr).then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(receiveIndividuals(normalized.individual));
      dispatch(receiveOrganizations(normalized.organization));
    });

    return {
      items: normalized.individual,
      ...mapKeysToCamelCase(res.data.meta || {}),
    };
  });
};

export const fetchIndividual = (id, isPublic = false, isLiveLink = false) => (dispatch) => {
  return GET("/public/api/v1/individuals/" + id + ( isLiveLink ? "/rfi_live_link_show" : "")).then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(receiveIndividuals(normalized.individual));
      dispatch(receiveOrganizations(normalized.organization));
    });

    return {
      items: normalized.individual,
      ...mapKeysToCamelCase(res.data.meta || {}),
    };
  });
};
