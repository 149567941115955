import React from "react";
import { useSelector } from "react-redux";
import { useScreenClass } from "react-grid-system";

import map from "lodash/map";

import useSharedStyles from "../show/useSharedStyles";

const DiversityFocus = ({ individualId }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const sharedClasses = useSharedStyles({ isDesktop });

  const individual = useSelector(
    (state) => state.individuals.items[individualId]
  );

  const diversityFocusItems =
    individual.attributes.matchPreferences.diversityFocus;

  if (!diversityFocusItems.length) return null;

  return (
    <div className={sharedClasses.rightSection}>
      <div className={sharedClasses.rightSectionHeader}>
        Candidate Diversity Focus
      </div>
      <div className={sharedClasses.sectionContent}>
        {map(diversityFocusItems, (name, i) => (
          <div
            key={`${name}-${i}`}
            className={sharedClasses.rightSectionContentItem}
          >
            {name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DiversityFocus;
