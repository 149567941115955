import React from "react";
import { useSelector } from "react-redux";
import { useScreenClass } from "react-grid-system";
import classnames from "classnames";

import map from "lodash/map";

import useSharedStyles from "./useSharedStyles";
import LoginModal from "../shared/LoginModal";

const Certifications = ({ individualId }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const sharedClasses = useSharedStyles({ isDesktop });
  const individual = useSelector(
    (state) => state.individuals.items[individualId]
  );

  const certifications = individual.attributes.certifications.data;

  if (!certifications.length) return null;

  return (
    <div className={sharedClasses.rightSection}>
      <div className={sharedClasses.rightSectionHeader}>Certifications</div>
      <div className={sharedClasses.sectionContent}>
        <LoginModal cta={
          (onClick) => (
            <React.Fragment>
              {map(
                certifications,
                ({ attributes: { name }, id }, i) => (
                  <div key={id} onClick={onClick} className={sharedClasses.rightSectionContentItem} title={"Download"}>
                    <span
                      className={classnames(
                        sharedClasses.externalLink,
                        sharedClasses.rightSectionContentItem
                      )}
                      title={"Download"}
                    >
                      <i
                        class="fa fa-download"
                        aria-hidden="true"
                        style={{ cursor: "pointer", marginRight: 4 }}
                      ></i>{name}
                    </span>
                  </div>
                )
              )}
            </React.Fragment>
          )
        }
          name={individual.attributes.publicName}
        />
      </div>
    </div>
  );
};

export default Certifications;
