import React, { useEffect, useState } from "react";
import { useScreenClass } from "react-grid-system";

import { GET } from "../../../../util/apiHelpers";
import FunnelBarChart from "../../../General/charts/FunnelBarChart";
import useSharedStyles from "../useSharedStyles";

import every from "lodash/every";
import values from "lodash/values";

const CandidateProgressionChart = ({
  presented,
  reviewing,
  interviewing,
  offers,
  hires,
  svgWidth = "100%"
}) => {
  const dataItems = [
    {
      id: "Hired",
      Candidates: hires,
      color: "rgba(0, 141, 174, 1)",
    },
    {
      id: "Offers",
      Candidates: offers,
      color: "rgba(0, 141, 174, 0.8)",
    },
    {
      id: "Interviews",
      Candidates: interviewing,
      color: "rgba(0, 141, 174, 0.6)",
    },
    {
      id: "Reviewing",
      Candidates: reviewing,
      color: "rgba(0, 141, 174, 0.4)",
    },
    {
      id: "Presented",
      Candidates: presented,
      color: "rgba(0, 141, 174, 0.2)",
    },
  ];

  return (
    <FunnelBarChart
      data={dataItems}
      valueKey="Candidates"
      height={250}
      axisWidth={100}
      svgWidth={svgWidth}
    />
  );
};

const LOADING = "LOADING";
const LOADED = "LOADED";
const ERROR = "ERROR";

const CandidateProgression = ({ individualId }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);

  const sharedClasses = useSharedStyles({ isDesktop });
  const [progressionStats, setProgressionStats] = useState(null);
  const [loadStatus, setLoadStatus] = useState(LOADING);

  useEffect(() => {
    GET(`public/api/v1/individuals/${individualId}/aggregate_progression_stats`)
      .then((res) => {
        setProgressionStats(res.data);
        setLoadStatus(LOADED);
      })
      .catch(() => {
        setLoadStatus(ERROR);
      });
  }, []);

  if (loadStatus !== LOADED || every(values(progressionStats), (val) => !val))
    return null;

  return (
    <div className={sharedClasses.sectionContent}>
      <h5 className={sharedClasses.subSectionHeader}>Candidate Progression</h5>
      <div className={sharedClasses.sectionContent} style={{ marginTop: 16 }}>
        {loadStatus === LOADED && (
          <CandidateProgressionChart {...progressionStats} />
        )}
      </div>
    </div>
  );
};

export default CandidateProgression;
