import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    fontSize: "13px",
    fontWeight: "300",
    fontFamily: "Poppins",
    lineHeight: "13px",
    margin: "2px 0 4px 0",
  },
  link: ({ selectedCount }) => ({
    color: selectedCount > 0 ? "#357BA6" : "#979797",
    cursor: selectedCount > 0 ? "pointer" : "default",
  }),
});

export default function UnselectAll({ selectedCount, resetSelections }) {
  const classes = useStyles({ selectedCount });

  return (
    <div className={classes.container}>
      <span
        onClick={selectedCount > 0 ? () => resetSelections() : null}
        className={classes.link}
      >
        Unselect All
      </span>
    </div>
  );
}
