import React from "react";
import Items from "./Items";

export default function ContentSection({ items, loading, currentPage, handlePageSelection }) {
  return (
    <React.Fragment>
      <div
        className="kt-portlet kt-portlet--height-fluid"
        style={{ height: "auto" }}
      >
        <div className="kt-portlet__body kt-portlet__body--fit">
          <Items {...{ individuals: items, loading, currentPage, handlePageSelection }} />
        </div>
      </div>
    </React.Fragment>
  );
}
