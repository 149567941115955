import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useScreenClass } from "react-grid-system";

import SearchSpecialtiesSection from "./SearchSpecialtiesSection";

import useSharedStyles from "../useSharedStyles";

import map from "lodash/map";

import { EmployeeWorkHistories } from "./EmployeeWorkHistories";

const SearchSpecialties = ({ individualId }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);

  const sharedClasses = useSharedStyles({ isDesktop });
  const individual = useSelector(
    (state) => state.individuals.items[individualId]
  );

  const {
    jobCastRoles,
    majorIndustries,
    levels,
    regions,
    states,
    specializations,
  } = individual.attributes.matchPreferences;

  const employeeWorkHistories = individual.attributes.employeeWorkHistories;

  const countryLabels = useMemo(
    () => map(regions, ({ name }) => name).sort(),
    [regions]
  );
  const stateLabels = useMemo(
    () => map(states, ({ name }) => name).sort(),
    [states]
  );

  return (
    <div className={sharedClasses.section}>
      <div className={sharedClasses.sectionHeader}>Search Capabilities</div>
      <div className={sharedClasses.sectionContent}>
        <SearchSpecialtiesSection
          title="Specializations"
          itemLabels={specializations}
          chipColor="#FEFFF7"
          isDesktop={isDesktop}
        />
        <SearchSpecialtiesSection
          title="Candidate Roles"
          itemLabels={jobCastRoles}
          chipColor="#FEFFF7"
          isDesktop={isDesktop}
        />
        <SearchSpecialtiesSection
          title="Industries"
          itemLabels={majorIndustries}
          chipColor="#F7FFFD"
          isDesktop={isDesktop}
        />
        <SearchSpecialtiesSection
          title="Candidate Locations - States"
          itemLabels={stateLabels}
          chipColor="#FFF7F7"
          isDesktop={isDesktop}
        />
        <SearchSpecialtiesSection
          title="Candidate Locations - Countries"
          itemLabels={countryLabels}
          chipColor="#FFF7F7"
          isDesktop={isDesktop}
        />
        <SearchSpecialtiesSection
          title="Candidate Levels"
          itemLabels={levels}
          chipColor="#F7F7FF"
          isDesktop={isDesktop}
        />
      </div>
      {employeeWorkHistories?.length > 0 && (
        <>
          <div className={sharedClasses.sectionHeader}>
            Relevant Employment History
          </div>
          <EmployeeWorkHistories items={employeeWorkHistories} classes={{}} />
        </>
      )}
    </div>
  );
};

export default SearchSpecialties;
