import React from "react";
import { useSelector } from "react-redux";
import { useScreenClass } from "react-grid-system";

import get from "lodash/get";
import RoomIcon from "@material-ui/icons/Room";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import classnames from "classnames";
import useSharedStyles from "./useSharedStyles";

import { phone_countries, us_states } from "./constants";
import LoginModal from "../shared/LoginModal";

const Overview = ({ individualId }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const sharedClasses = useSharedStyles({ isDesktop });

  const individual = useSelector(
    (state) => state.individuals.items[individualId]
  );

  const { countryCode, yearsExperience, publicName, state, yearsOnPlatform } =
    individual.attributes;

  const countryName =
    countryCode === "US" ? "USA" : get(phone_countries[countryCode], "name");

  const stateName = countryCode === "US" ? us_states[state] : null;

  const locationStr = stateName ? [stateName, countryName].join(", ") : countryName;

  return (
    <div className={sharedClasses.rightSection}>
      <div className={sharedClasses.rightSectionHeader}>Overview</div>
      <div className={sharedClasses.sectionContent}>
        {locationStr && (
          <div className={sharedClasses.rightSectionContentItem}>
            <RoomIcon style={{ marginRight: 8, color: "#B0BEC5" }} />{" "}
            {locationStr}
          </div>
        )}
        {yearsExperience && (
          <div className={sharedClasses.rightSectionContentItem}>
            <AccessTimeIcon style={{ marginRight: 8, color: "#B0BEC5" }} />{" "}
            {yearsExperience} year
            {yearsExperience !== 1 && "s"} experience
          </div>
        )}
        {yearsOnPlatform > 0 && (
          <div className={sharedClasses.rightSectionContentItem}>
            <img
              style={{ display: "inline", height: 19.5, marginRight: 11 }}
              src={
                "https://recruitifi-public.s3.amazonaws.com/default_avatars/rfi_individual_square.png"
              }
              alt="RecruitiFi-logo"
            />
            Member for {yearsOnPlatform} year{yearsOnPlatform !== 1 && "s"}
          </div>
        )}
        <LoginModal
          cta={(onClick) => (
            <span
              onClick={onClick}
              className={classnames(
                sharedClasses.externalLink,
                sharedClasses.rightSectionContentItem
              )}
              style={{ cursor: "pointer" }}
            >
              <LinkedInIcon style={{ marginRight: 8, color: "#B0BEC5" }} />{" "}
              LinkedIn Profile
            </span>
          )}
          name={publicName}
        />
      </div>
    </div>
  );
};

export default Overview;
