export const phone_countries = {
  AD: { number: "376", name: "Andorre" },
  AE: { number: "971", name: "United Arab Emirates" },
  AF: { number: "93", name: "Afghanistan" },
  AG: { number: "1", name: "Antigua and Barbuda" },
  AI: { number: "1", name: "Anguilla" },
  AL: { number: "355", name: "Albania" },
  AM: { number: "374", name: "Armenia" },
  AN: { number: "599", name: "Netherlands Antilles" },
  AO: { number: "244", name: "Angola" },
  AQ: { number: "672", name: "Antarctica" },
  AR: { number: "54", name: "Argentina" },
  AS: { number: "1", name: "American Samoa" },
  AT: { number: "43", name: "Austria" },
  AU: { number: "61", name: "Australia" },
  AW: { number: "297", name: "Aruba" },
  AX: { number: "358", name: "Åland Islands" },
  AZ: { number: "994", name: "Azerbaijan" },
  BA: { number: "387", name: "Bosnia and Herzegovina" },
  BB: { number: "1", name: "Barbade" },
  BD: { number: "880", name: "Bangladesh" },
  BE: { number: "32", name: "Belgium" },
  BF: { number: "226", name: "Burkina Faso" },
  BG: { number: "359", name: "Bulgaria" },
  BH: { number: "973", name: "Bahrain" },
  BI: { number: "257", name: "Burundi" },
  BJ: { number: "229", name: "Benin" },
  BL: { number: "590", name: "Saint Barthélemy" },
  BM: { number: "1", name: "Bermuda" },
  BN: { number: "673", name: "Brunei" },
  BO: { number: "591", name: "Bolivia" },
  BQ: { number: "599", name: "Bonaire, Sint Eustatius and Saba" },
  BR: { number: "55", name: "Brazil" },
  BS: { number: "1", name: "Bahamas" },
  BT: { number: "975", name: "Bhutan" },
  BV: { number: "", name: "Bouvet Island" },
  BW: { number: "267", name: "Botswana" },
  BY: { number: "375", name: "Belarus" },
  BZ: { number: "501", name: "Belize" },
  CA: { number: "1", name: "Canada" },
  CC: { number: "61", name: "Cocos (Keeling) Islands" },
  CD: { number: "243", name: "Congo (Dem. Rep.)" },
  CF: { number: "236", name: "Central African Republic" },
  CG: { number: "242", name: "Congo" },
  CH: { number: "41", name: "Switzerland" },
  CI: { number: "225", name: "Côte D'Ivoire" },
  CK: { number: "682", name: "Cook Islands" },
  CL: { number: "56", name: "Chile" },
  CM: { number: "237", name: "Cameroon" },
  CN: { number: "86", name: "China" },
  CO: { number: "57", name: "Colombia" },
  CR: { number: "506", name: "Costa Rica" },
  CU: { number: "53", name: "Cuba" },
  CV: { number: "238", name: "Cape Verde" },
  CW: { number: "599", name: "Curaçao" },
  CX: { number: "61", name: "Christmas Island" },
  CY: { number: "357", name: "Cyprus" },
  CZ: { number: "420", name: "Czech Republic" },
  DE: { number: "49", name: "Germany" },
  DJ: { number: "253", name: "Djibouti" },
  DK: { number: "45", name: "Denmark" },
  DM: { number: "1", name: "Dominica" },
  DO: { number: "1", name: "Dominican Republic" },
  DZ: { number: "213", name: "Algeria" },
  EC: { number: "593", name: "Ecuador" },
  EE: { number: "372", name: "Estonia" },
  EG: { number: "20", name: "Egypt" },
  EH: { number: "212", name: "Western Sahara" },
  ER: { number: "291", name: "Eritrea" },
  ES: { number: "34", name: "Spain" },
  ET: { number: "251", name: "Ethiopia" },
  FI: { number: "358", name: "Finland" },
  FJ: { number: "679", name: "Fiji" },
  FK: { number: "500", name: "Falkland Islands" },
  FM: { number: "691", name: "Micronesia" },
  FO: { number: "298", name: "Faroe Islands" },
  FR: { number: "33", name: "France" },
  GA: { number: "241", name: "Gabon" },
  GB: { number: "44", name: "United Kingdom" },
  GD: { number: "1", name: "Grenada" },
  GE: { number: "995", name: "Georgia" },
  GF: { number: "594", name: "French Guiana" },
  GG: { number: "44", name: "Guernsey and Alderney" },
  GH: { number: "233", name: "Ghana" },
  GI: { number: "350", name: "Gibraltar" },
  GL: { number: "299", name: "Greenland" },
  GM: { number: "220", name: "Gambia" },
  GN: { number: "224", name: "Guinea" },
  GP: { number: "590", name: "Guadeloupe" },
  GQ: { number: "240", name: "Equatorial Guinea" },
  GR: { number: "30", name: "Greece" },
  GS: { number: "500", name: "South Georgia and the South Sandwich Islands" },
  GT: { number: "502", name: "Guatemala" },
  GU: { number: "1", name: "Guam" },
  GW: { number: "245", name: "Guinea-Bissau" },
  GY: { number: "592", name: "Guyana" },
  HK: { number: "852", name: "Hong Kong" },
  HM: { number: "", name: "Heard and McDonald Islands" },
  HN: { number: "504", name: "Honduras" },
  HR: { number: "385", name: "Croatia" },
  HT: { number: "509", name: "Haiti" },
  HU: { number: "36", name: "Hungary" },
  ID: { number: "62", name: "Indonesia" },
  IE: { number: "353", name: "Ireland" },
  IL: { number: "972", name: "Israel" },
  IM: { number: "44", name: "Isle of Man" },
  IN: { number: "91", name: "India" },
  IO: { number: "246", name: "British Indian Ocean Territory" },
  IQ: { number: "964", name: "Iraq" },
  IR: { number: "98", name: "Iran" },
  IS: { number: "354", name: "Iceland" },
  IT: { number: "39", name: "Italy" },
  JE: { number: "44", name: "Jersey" },
  JM: { number: "1", name: "Jamaica" },
  JO: { number: "962", name: "Jordan" },
  JP: { number: "81", name: "Japan" },
  KE: { number: "254", name: "Kenya" },
  KG: { number: "996", name: "Kyrgyzstan" },
  KH: { number: "855", name: "Cambodia" },
  KI: { number: "686", name: "Kiribati" },
  KM: { number: "269", name: "Comoros" },
  KN: { number: "1", name: "Saint Kitts and Nevis" },
  KP: { number: "850", name: "Korea (North)" },
  KR: { number: "82", name: "South Korea" },
  KW: { number: "965", name: "Kuwait" },
  KY: { number: "1", name: "Cayman Islands" },
  KZ: { number: "7", name: "Kazakhstan" },
  LA: { number: "856", name: "Laos" },
  LB: { number: "961", name: "Lebanon" },
  LC: { number: "1", name: "Saint Lucia" },
  LI: { number: "423", name: "Liechtenstein" },
  LK: { number: "94", name: "Sri Lanka" },
  LR: { number: "231", name: "Liberia" },
  LS: { number: "266", name: "Lesotho" },
  LT: { number: "370", name: "Lithuania" },
  LU: { number: "352", name: "Luxembourg" },
  LV: { number: "371", name: "Latvia" },
  LY: { number: "218", name: "Libya" },
  MA: { number: "212", name: "Morocco" },
  MC: { number: "377", name: "Monaco" },
  MD: { number: "373", name: "Moldova" },
  ME: { number: "382", name: "Crna Gora" },
  MF: { number: "590", name: "Saint Martin" },
  MG: { number: "261", name: "Madagascar" },
  MH: { number: "692", name: "Marshall Islands" },
  MK: { number: "389", name: "Macedonia" },
  ML: { number: "223", name: "Mali" },
  MM: { number: "95", name: "Myanmar" },
  MN: { number: "976", name: "Mongolia" },
  MO: { number: "853", name: "Macao" },
  MP: { number: "1", name: "Northern Mariana Islands" },
  MQ: { number: "596", name: "Martinique" },
  MR: { number: "222", name: "Mauritania" },
  MS: { number: "1", name: "Montserrat" },
  MT: { number: "356", name: "Malta" },
  MU: { number: "230", name: "Mauritius" },
  MV: { number: "960", name: "Maldives" },
  MW: { number: "265", name: "Malawi" },
  MX: { number: "52", name: "Mexico" },
  MY: { number: "60", name: "Malaysia" },
  MZ: { number: "258", name: "Mozambique" },
  NA: { number: "264", name: "Namibia" },
  NC: { number: "687", name: "New Caledonia" },
  NE: { number: "227", name: "Niger" },
  NF: { number: "672", name: "Norfolk Island" },
  NG: { number: "234", name: "Nigeria" },
  NI: { number: "505", name: "Nicaragua" },
  NL: { number: "31", name: "Netherlands" },
  NO: { number: "47", name: "Norway" },
  NP: { number: "977", name: "Nepal" },
  NR: { number: "674", name: "Nauru" },
  NU: { number: "683", name: "Niue" },
  NZ: { number: "64", name: "New Zealand" },
  OM: { number: "968", name: "Oman" },
  PA: { number: "507", name: "Panama" },
  PE: { number: "51", name: "Peru" },
  PF: { number: "689", name: "French Polynesia" },
  PG: { number: "675", name: "Papua New Guinea" },
  PH: { number: "63", name: "Philippines" },
  PK: { number: "92", name: "Pakistan" },
  PL: { number: "48", name: "Poland" },
  PM: { number: "508", name: "Saint Pierre and Miquelon" },
  PN: { number: "", name: "Pitcairn" },
  PR: { number: "1", name: "Puerto Rico" },
  PS: { number: "970", name: "Palestine" },
  PT: { number: "351", name: "Portugal" },
  PW: { number: "680", name: "Palau" },
  PY: { number: "595", name: "Paraguay" },
  QA: { number: "974", name: "Qatar" },
  RE: { number: "262", name: "Réunion" },
  RO: { number: "40", name: "Romania" },
  RS: { number: "381", name: "Serbia" },
  RU: { number: "7", name: "Russia" },
  RW: { number: "250", name: "Rwanda" },
  SA: { number: "966", name: "Saudi Arabia" },
  SB: { number: "677", name: "Solomon Islands" },
  SC: { number: "248", name: "Seychelles" },
  SD: { number: "249", name: "Sudan" },
  SE: { number: "46", name: "Sweden" },
  SG: { number: "65", name: "Singapore" },
  SH: { number: "290", name: "Saint Helena" },
  SI: { number: "386", name: "Slovenia" },
  SJ: { number: "47", name: "Svalbard and Jan Mayen" },
  SK: { number: "421", name: "Slovakia" },
  SL: { number: "232", name: "Sierra Leone" },
  SM: { number: "378", name: "San Marino" },
  SN: { number: "221", name: "Senegal" },
  SO: { number: "252", name: "Somalia" },
  SR: { number: "597", name: "Suriname" },
  SS: { number: "211", name: "South Sudan" },
  ST: { number: "239", name: "São Tomé and Príncipe" },
  SV: { number: "503", name: "El Salvador" },
  SX: { number: "1", name: "Sint Maarten" },
  SY: { number: "963", name: "Syria" },
  SZ: { number: "268", name: "Swaziland" },
  TC: { number: "1", name: "Turks and Caicos Islands" },
  TD: { number: "235", name: "Chad" },
  TF: { number: "", name: "French Southern Territories" },
  TG: { number: "228", name: "Togo" },
  TH: { number: "66", name: "Thailand" },
  TJ: { number: "992", name: "Tajikistan" },
  TK: { number: "690", name: "Tokelau" },
  TL: { number: "670", name: "East Timor" },
  TM: { number: "993", name: "Turkmenistan" },
  TN: { number: "216", name: "Tunisia" },
  TO: { number: "676", name: "Tonga" },
  TR: { number: "90", name: "Turkey" },
  TT: { number: "1", name: "Trinidad and Tobago" },
  TV: { number: "688", name: "Tuvalu" },
  TW: { number: "886", name: "Taiwan" },
  TZ: { number: "255", name: "Tanzania" },
  UA: { number: "380", name: "Ukraine" },
  UG: { number: "256", name: "Uganda" },
  UM: { number: "", name: "United States Minor Outlying Islands" },
  US: { number: "1", name: "United States of America" },
  UY: { number: "598", name: "Uruguay" },
  UZ: { number: "998", name: "Uzbekistan" },
  VA: { number: "39", name: "Vatican City" },
  VC: { number: "1", name: "Saint Vincent and the Grenadines" },
  VE: { number: "58", name: "Venezuela (Bolivarian Republic of)" },
  VG: { number: "1", name: "British Virgin Islands" },
  VI: { number: "1", name: "Virgin Islands of the United States" },
  VN: { number: "84", name: "Vietnam" },
  VU: { number: "678", name: "Vanuatu" },
  WF: { number: "681", name: "Wallis and Futuna" },
  WS: { number: "685", name: "Samoa" },
  XK: { number: "383", name: "Kosovo" },
  YE: { number: "967", name: "Yemen" },
  YT: { number: "262", name: "Mayotte" },
  ZA: { number: "27", name: "South Africa" },
  ZM: { number: "260", name: "Zambia" },
  ZW: { number: "263", name: "Zimbabwe" },
};

export const us_states = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};
