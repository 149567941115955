import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useScreenClass } from "react-grid-system";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { AvatarIcon } from "../../../General/AvatarIcon";
import useSharedStyles from "./useSharedStyles";
import LoginModal from "../shared/LoginModal";
import { PendingApprovalContext } from "../Show";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 12,
  },
  textSection: {
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
  agencyName: ({ isDesktop }) => ({
    fontSize: isDesktop ? 18 : 14,
  }),
}));

const Header = ({ individualId }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const individual = useSelector(
    (state) => state.individuals.items[individualId]
  );

  const userPendingApproval = useContext(PendingApprovalContext);
  const classes = useStyles({ isDesktop });
  const sharedClasses = useSharedStyles({ isDesktop });

  return (
    <div className={classes.container}>
      <AvatarIcon user={individual} height={isDesktop ? 100 : 80} />
      <div className={classes.textSection}>
        <div className={sharedClasses.sectionHeader}>
          {individual.attributes.publicName}
        </div>
        <div className={classes.agencyName}>
          <div
            className={`absolute filter blur-sm select-none text-astral-600`}
            style={{
              filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius='3')",
              fontSize: "16px",
              fontWeight: "500",
              fontFamily: "Poppins",
              lineHeight: "2.28571rem",
            }}
          >
            Lorem Ipsum Delano Agency
          </div>
          {userPendingApproval ? (
            <div className="absolute font-semibold">
              Agency name shown here pending approval
            </div>
          ) : (
            <div className="absolute font-semibold cursor-pointer">
              <LoginModal
                cta={(onClick) => (
                  <span onClick={onClick} className="text-astral underline">
                    Sign in
                  </span>
                )}
                name={individual.attributes.publicName}
              />{" "}
              to see Agency name
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
