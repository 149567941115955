import React from "react";
import { useSelector } from "react-redux";
import { useScreenClass } from "react-grid-system";
import classnames from "classnames";

import map from "lodash/map";

import useSharedStyles from "./useSharedStyles";

const Certifications = ({ individualId }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const sharedClasses = useSharedStyles({ isDesktop });
  const individual = useSelector(
    (state) => state.individuals.items[individualId]
  );
  const organization = useSelector(
    (state) => state.organizations.items[individual.attributes.organizationId]
  );

  const certifications = organization.attributes.certifications.data;

  if (!certifications.length) return null;

  return (
    <div className={sharedClasses.rightSection}>
      <div className={sharedClasses.rightSectionHeader}>Certifications</div>
      <div className={sharedClasses.sectionContent}>
        {map(
          certifications,
          ({ attributes: { name, supportingDocument }, id }, i) => (
            <div key={id} className={sharedClasses.rightSectionContentItem} title={"Download"}>
              <a
                href={supportingDocument.url}
                rel="noopener noreferrer"
                target="_blank"
                className={classnames(
                  sharedClasses.externalLink,
                  sharedClasses.rightSectionContentItem
                )}
                title={"Download"}
              >
                <i
                  class="fa fa-download"
                  aria-hidden="true"
                  style={{ cursor: "pointer", marginRight: 4 }}
                  onClick={() => window.open(supportingDocument.url)}
                ></i>{name}
              </a>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Certifications;
