import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import Truncate from "react-truncate";
import LoginModal from "../../shared/LoginModal";
import { DirectoryContext } from "../IndexComponent";

export const styles = createUseStyles({
  name: {
    fontSize: "24px",
    fontWeight: "500",
    fontFamily: "Poppins",
    lineHeight: "2.28571rem",
    color: "#357BA6",
  },
  organizationName: {
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "Poppins",
    lineHeight: "2.28571rem",
  },
  description: {
    marginTop: 12,
  },
  mobileName: {
    paddingLeft: 0,
  },
  itemContainer: {
    margin: 0,
    padding: 20,
    textIndent: 0,
    listStyleType: 0,
    fontSize: "1.125rem",
    fontWeight: "300",
    fontFamily: "Poppins",
    lineHeight: "2.28571rem",
    color: "48465B",
    display: "flex",
    flexWrap: "wrap",
  },
  avatarColumn: {
    paddingLeft: 0,
    paddingRight: 20,
    display: "flex",
  },
  infoColumn: {
    paddingLeft: 0,
    paddingRight: 20,
  },
  ctaColumn: {
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  linkedinIcon: {
    display: "block",
    float: "right",
    fontSize: 38.9999,
    color: "#0e76a8",
    marginLeft: 12,
  },
});

export const Name = ({ firstName, lastName, id }) => (
  <a href={`/${id}`} className={styles().name}>
    {firstName} {lastName}
  </a>
);

export const OrganizationName = ({ individualName }) => {
  const { userPendingApproval } = useContext(DirectoryContext);

  return (
    <div>
      <div
        className={`absolute filter blur-sm select-none text-astral-600 ${
          styles().organizationName
        }`}
        style={{
          filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius='3')",
          left: 8,
        }}
      >
        Lorem Ipsum Delano Agency
      </div>
      {userPendingApproval ? (
        <div className="absolute font-semibold">
          Agency name shown here pending approval
        </div>
      ) : (
        <div className="absolute font-semibold cursor-pointer">
          <LoginModal
            cta={(onClick) => (
              <span onClick={onClick} className="text-astral underline">
                Sign in
              </span>
            )}
            name={individualName}
          />{" "}
          to see Agency name
        </div>
      )}
    </div>
  );
};

export const Description = ({ children }) => {
  if (!children) return null;
  return (
    <div className={styles().description}>
      <Truncate lines={3}>{children}</Truncate>
    </div>
  );
};
