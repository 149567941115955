import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash/debounce";

import { styles } from "./search/utils";
import Input from "./search/Input";

const Search = ({ search, loading, setSearch }) => {
  const classes = styles({});

  const [inputValue, setInputValue] = useState(search);
  const [inputLoading, setInputLoading] = useState(false);

  useEffect(() => {
    if (!inputLoading && search !== inputValue && search === "") {
      setInputValue(search);
    }
  }, [search]);

  useEffect(() => {
    if (inputValue && inputValue.length > 0) {
      setInputLoading(true);
    }
    performSearch(inputValue);
  }, [inputValue]);

  const performSearch = useCallback(
    debounce((search) => {
      setInputLoading(false);
      setSearch(search);
    }, 500),
    []
  );

  return (
    <div
      className={`form-group ${classes.searchContainer}`}
      style={{ width: "100%" }}
    >
      <Input
        {...{ search, loading, inputValue, setInputValue, inputLoading }}
      />
    </div>
  );
};

export default Search;
