import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => {
  return {
    text: ({ small, light }) => ({
      color: light ? "#F5F5F5" : "#90A4AE",
      fontSize: small ? 12 : 16,
      fontWeight: 400,
      letterSpacing: 0.15,
      fontFamily: "'Poppins', helvetica",
      "&:hover": {
        textDecoration: "underline",
        color: "#90A4AE",
        cursor: "pointer",
      },
    }),
    logo: ({ small }) => ({
      marginLeft: small ? 8 : 12,
      height: small ? 26 : 32,
      width: small ? 115 : 145,
    }),
  };
});

const RFITag = ({ profile = true, small = false, light = false }) => {
  const classes = useStyles({ small, light });

  return (
    <a
      className={classes.text}
      href={"https://www.recruitifi.com"}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span>{profile && "profile "}powered by </span>
      <img
        style={{ display: "inline" }}
        src={light ? "https://recruitifi-public.s3.amazonaws.com/recruitifi-logo-email.png" : "https://recruitifi-public.s3.amazonaws.com/TALK-rfi-logo.png"}
        alt="RecruitiFi-logo"
        className={classes.logo}
      />
    </a>
  );
};

export default RFITag;
