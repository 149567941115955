import React, { useState } from "react";
import { Disclosure, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import map from "lodash/map";
import each from "lodash/each";
import queryString from "query-string";
import { DIVERSITY_FOCUS_OPTIONS } from "../../../Settings/shared/constants";

const locations = {
  "New York City": "New York",
  "Los Angeles": "California",
  "Chicago": "Illinois",
  "Houston": "Texas",
  "Phoenix": "Arizona",
  "Philadelphia": "Pennsylvania",
  "San Antonio": "Texas",
  "San Diego": "California",
  "Dallas": "Texas",
  "San Jose": "California",
  "Austin": "Texas",
  "Jacksonville": "Florida",
  "Fort Worth": "Texas",
  "Columbus": "Ohio",
  "Indianapolis": "Indiana",
  "Charlotte": "North Carolina",
  "San Francisco": "California",
  "Seattle": "Washington",
  "Denver": "Colorado",
  "Washington": "District of Columbia"
}

const offerings = [
  'Civic & Education',
  'Consulting',
  'Diversity',
  'Finance',
  'Health Care',
  'Legal',
  'Manufacturing & Labor',
  'Marketing & Creative',
  'Operations & HR',
  'Sales',
  'Science & Engineering',
  'Service & Hospitality',
  'Technology & Software'
]

const locationOfferings = []

each(Object.keys(locations), location => {
  locationOfferings.push({
    location: location,
    offerings: map(offerings, role => {
      const params = {
        locations: locations[location],
        order: "desc_rating"
      }

      if (role === "Diversity") {
        params.diversity_focus = DIVERSITY_FOCUS_OPTIONS
      } else {
        params.roles = role
      }

      const query = queryString.stringify(params)

      return ({
        title: (location + " " + role + " Recruiters"),
        path: "/search?" + query
      })
    })
  })
})

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function LocationOfferingSection({ locationOffering, setOpenLocation, openLocation }) {
  return (
    <Disclosure as="div" key={locationOffering.location} className="pt-3">
      {({ open }) => (
        <>
          <div className="text-lg" onClick={() => setOpenLocation(locationOffering.location)}>
            <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400 border-b pb-3 border-gray-600">
              <span className="text-2xl font-medium text-gray-600">{locationOffering.location}</span>
              <span className="ml-6 h-7 flex items-center">
                <ChevronDownIcon
                  className={classNames(open && openLocation === locationOffering.location ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </div>
          <Transition
            show={open && openLocation === locationOffering.location}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel as="dd" className="mt-2 pr-12">
              <ul>
                {map(locationOffering.offerings, ({ path, title }) => (
                  <li className="text-lg" key={title}>
                    <a className="text-astral" href={path}>{title}</a>
                  </li>
                ))}
              </ul>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}

export default function LocationOfferings() {
  const [openLocation, setOpenLocation] = useState("New York City");

  const createSection = (locationOffering) => (
    <LocationOfferingSection
      key={locationOffering.location}
      {...{ locationOffering, setOpenLocation, openLocation }}
    />
  )

  return (
    <div className="bg-white pt-12 pb-24">
      <div className="kt-container">
        <div className="w-full">
          <h2 className="text-4xl tracking-wide font-medium text-gray-800 sm:text-4xl mb-3">
            Find Agency Recruiters by City
          </h2>
          <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12">
            {map([0, 1, 2], col => (
              <div key={col}>
                {locationOfferings.slice(col * 7, (col + 1) * 7).map(locationOffering => createSection(locationOffering))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
