import React from "react";
import { LOADED } from "./Index";
import { RatingDisplay } from "./RatingDisplay";

export default function CandidateRatings({
  loadStatus,
  sharedClasses,
  candidateRatingsData,
}) {
  const {
    overall: candidateOverall,
    communication,
    knowledge,
    professionalism,
    numRatees: candidateNumRatees,
  } = candidateRatingsData;

  return (
    loadStatus === LOADED &&
    !!candidateNumRatees &&
    candidateNumRatees > 0 && (
      <>
        <h5
          className={sharedClasses.subSectionHeader}
          style={{ marginBottom: 0, marginTop: 8 }}
        >
          Ratings from Candidates
        </h5>
        <div className={sharedClasses.sectionContent}>
          <>
            <RatingDisplay
              title={<strong style={{ color: "#48465B" }}>Overall</strong>}
              rating={candidateOverall}
              numRatees={candidateNumRatees}
            />
            <RatingDisplay
              title="Communication"
              rating={communication}
              numRatees={candidateNumRatees}
            />
            <RatingDisplay
              title="Knowledge"
              rating={knowledge}
              numRatees={candidateNumRatees}
            />
            <RatingDisplay
              title="Professionalism"
              rating={professionalism}
              numRatees={candidateNumRatees}
            />
          </>
        </div>
      </>
    )
  );
}
