import { createMuiTheme } from "@material-ui/core/styles";

const muiTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 544,
      md: 768,
      lg: 1024,
      xl: 1200,
    },
  },
  palette: {
    primary: {
      main: "#357BA6",
    },
    secondary: {
      main: "#357BA6",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "1rem",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#357BA6",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
    MuiSnackbarContent: {
      root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      },
      message: {
        fontSize: 16,
      },
    },
  },
});

export default muiTheme;
