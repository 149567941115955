import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import RFITag from "./partials/RFITag";

const useStyles = makeStyles(() => {
  return {
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      padding: 24
    },
  };
});

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <RFITag />
    </div>
  );
};

export default Footer;
