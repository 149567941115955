import React, { useEffect, useState, useContext } from "react";
import { createUseStyles } from "react-jss";
import { Visible } from "react-grid-system";
import AddIcon from "@material-ui/icons/Add";
import find from "lodash/find";

import Search from "./Search";
import Order from "../../General/Order";
import { DirectoryContext } from "./IndexComponent";

import { AGENCIES_SORT_OPTIONS } from "./order/orderHelpers";

const useStyles = createUseStyles({
  header: {
    color: "#595d6e",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.07px",
    lineHeight: "24px",
    padding: "20px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    borderBottom: 0,
    backgroundColor: "white",
    position: "-webkit-sticky",
    position: "sticky",
    height: "auto",
    right: 0,
    top: 70,
    zIndex: 1,
    transition: "height 0.3s ease",
    marginBottom: 24,
    display: "flex",
    flexDirection: "row",
  },
  secondPartHeader: { display: "flex" },
  "@media only screen and (max-width: 950px)": {
    header: {
      flexDirection: "column",
    },
    secondPartHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 12,
    },
  },
});

export default function Toolbar({ loading, search, setSearch, order, setOrder }) {
  const classes = useStyles();
  const { state, toggleDrawer } = useContext(DirectoryContext);
  const [filterCount, setFilterCount] = useState(0);

  const defaultValue = find(AGENCIES_SORT_OPTIONS, { value: order });

  useEffect(() => {
    let count = 0;
    Object.keys(state).forEach((key) => {
      if (state[key]["value"]) count += 1;
    });
    setFilterCount(count);
  }, [state]);

  return (
    <div className={`kt-portlet kt-portlet__body ${classes.header}`}>
      <Search {...{ loading, search, setSearch }} />
      <div className={classes.secondPartHeader}>
        <Visible xs sm md>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "300",
              fontFamily: "Poppins",
              lineHeight: "36px",
              color: "#357BA6",
              cursor: "pointer",
            }}
            onClick={toggleDrawer}
          >
            Filters {filterCount > 0 && `[${filterCount}]`}
            <AddIcon fontSize={"small"} />
          </div>
        </Visible>
        <Order
          setOrder={setOrder}
          defaultValue={defaultValue}
          options={AGENCIES_SORT_OPTIONS}
          isSearchable={false}
        />
      </div>
    </div>
  );
}
