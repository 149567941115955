import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import map from "lodash/map";
import get from "lodash/get";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Chip from "@material-ui/core/Chip";

import { LocationMarkerIcon, LibraryIcon } from "@heroicons/react/solid";
import { ClockIcon, BriefcaseIcon } from "@heroicons/react/outline";
import { phone_countries, us_states } from "../../show/constants";

const CHIPS_TO_DISPLAY = 3;

const useStyles = makeStyles(() => ({
  chipItem: ({ chipColor }) => ({
    margin: 3,
    backgroundColor: chipColor,
    fontSize: 14,
  }),
  chipLabel: {
    fontWeight: 400,
  },
}));

const ChipItem = ({ label, chipColor }) => {
  const classes = useStyles({ chipColor });

  return (
    <Chip
      label={label}
      size="medium"
      classes={{ root: classes.chipItem, label: classes.chipLabel }}
      variant="outlined"
    />
  );
};

const SeeMoreChipItem = ({ id, chipColor }) => {
  const classes = useStyles({ chipColor });

  return (
    <Chip
      label={"+ More"}
      size="medium"
      classes={{ root: classes.chipItem, label: classes.chipLabel }}
      variant="outlined"
      href={"/" + id}
      clickable
      component="a"
    />
  );
};

export default function AttributesRow({ id }) {
  const individual = useSelector(
    (state) => state.individuals.items[id],
    shallowEqual
  );

  const {
    state,
    countryCode,
    yearsExperience,
    matchPreferences,
  } = individual.attributes;

  const { jobCastRoles, majorIndustries, specializations } = matchPreferences;

  const countryName =
    countryCode === "US" ? "USA" : get(phone_countries[countryCode], "name");

  const stateString = us_states[state];
  const locationString = stateString
    ? `${us_states[state]}, ${countryName}`
    : countryName;

  return (
    <div className="attributes-container">
      <div className="icons-container">
        <div className="icon-container">
          <LocationMarkerIcon className="attribute-icon" aria-hidden="true" />
          {locationString}
        </div>
        <div className="icon-container">
          <BriefcaseIcon className="attribute-icon" aria-hidden="true" />
          {jobCastRoles.length} Functional Role{jobCastRoles.length !== 1 && "s"}
        </div>
        <div className="icon-container">
          <ClockIcon className="attribute-icon" aria-hidden="true" />
          {yearsExperience} Year{yearsExperience !== 1 && "s"} Experience
        </div>
        <div className="icon-container">
          <LibraryIcon className="attribute-icon" aria-hidden="true" />
          {majorIndustries.length}{" "}
          {majorIndustries.length === 1 ? "Industry" : "Industries"}
        </div>
      </div>
      <div className="chips-container">
        {specializations.length ? (
          <div>
            {map(specializations.slice(0, 5), (role) => (
              <ChipItem key={role} label={role} chipColor={"#F7FAFF"} />
            ))}
            {specializations.length > 5 && (
              <SeeMoreChipItem id={id} chipColor={"#F7FAFF"} />
            )}
          </div>
        ) : (
          <>
            <div>
              {map(jobCastRoles.slice(0, CHIPS_TO_DISPLAY), (role) => (
                <ChipItem key={role} label={role} chipColor={"#FEFFF7"} />
              ))}
            </div>
            <div>
              {map(majorIndustries.slice(0, CHIPS_TO_DISPLAY), (industry) => (
                <ChipItem
                  key={industry}
                  label={industry}
                  chipColor={"#F7FFFD"}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
