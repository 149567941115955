import React, { useEffect, useState } from "react";
import { useScreenClass } from "react-grid-system";

import makeStyles from "@material-ui/core/styles/makeStyles";

import StarRating from "../../shared/partials/StarRating";
import useSharedStyles from "../useSharedStyles";

import { GET } from "../../../../../util/apiHelpers";

import round from "lodash/round";
import { mapKeysToCamelCase } from "../../../../../util/formatHelpers";

const useStyles = makeStyles((theme) => ({
  ratingFieldContainer: ({ isDesktop }) => ({
    display: "flex",
    flexDirection: isDesktop ? "row" : "column",
    alignItems: isDesktop ? "center" : "flex-start",
    justifyContent: "space-between",
    width: theme.spacing(45),
    padding: `${theme.spacing(1)}px 0px`,
  }),
  ratingLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "13px",
    whiteSpace: "normal",
    fontWeight: 600,
    color: "#B0BEC5",
    fontFamily: "Poppins",
    letterSpacing: "0.4px",
    textTransform: "uppercase",
  },
}));

const RatingField = ({ title, rating, numRatees }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const classes = useStyles({ isDesktop });
  const sharedClasses = useSharedStyles();

  return (
    <div className={classes.ratingFieldContainer}>
      <div className={classes.ratingLabel}>{title}</div>
      <div className={sharedClasses.ratingValueContainer}>
        <StarRating rating={round(rating * 2, 0) / 2} isDesktop={isDesktop} />
        <div className={sharedClasses.ratingsValueNumber}>
          ({numRatees ? round(rating, 2) : "N/A"})
        </div>
      </div>
    </div>
  );
};

const LOADING = "LOADING";
const LOADED = "LOADED";
const ERROR = "ERROR";

const CandidateRatings = ({ individualId }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);

  const sharedClasses = useSharedStyles({ isDesktop });
  const [ratingsData, setRatingsData] = useState({});
  const [loadStatus, setLoadStatus] = useState(LOADING);

  useEffect(() => {
    GET(`/public/api/v1/individuals/${individualId}/aggregate_ratings`)
      .then((res) => {
        setRatingsData(mapKeysToCamelCase(res.data));
        setLoadStatus(LOADED);
      })
      .catch(() => {
        setLoadStatus(ERROR);
      });
  }, []);

  const { overall, communication, knowledge, professionalism, numRatees } =
    ratingsData;

  return (
    <div className={sharedClasses.sectionContent}>
      <h5 className={sharedClasses.subSectionHeader}>Ratings from Candidates</h5>
      <div className={sharedClasses.sectionContent}>
        {loadStatus === LOADED && (
          <React.Fragment>
            <RatingField
              title="Overall"
              rating={overall}
              numRatees={numRatees}
            />
            <RatingField
              title="Communication"
              rating={communication}
              numRatees={numRatees}
            />
            <RatingField
              title="Knowledge"
              rating={knowledge}
              numRatees={numRatees}
            />
            <RatingField
              title="Professionalism"
              rating={professionalism}
              numRatees={numRatees}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default CandidateRatings;
