import React from "react";
import { useScreenClass } from "react-grid-system";

import makeStyles from "@material-ui/core/styles/makeStyles";

import StarRating from "../partials/StarRating";
import useSharedStyles from "../../../../Individuals/show/useSharedStyles";

import round from "lodash/round";

const useStyles = makeStyles((theme) => ({
  ratingFieldContainer: ({ isDesktop }) => ({
    display: "flex",
    flexDirection: isDesktop ? "row" : "column",
    alignItems: isDesktop ? "center" : "flex-start",
    justifyContent: "space-between",
    width: theme.spacing(45),
    padding: `${theme.spacing(1)}px 0px`,
  }),
  ratingLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "13px",
    whiteSpace: "normal",
    fontWeight: 600,
    color: "#B0BEC5",
    fontFamily: "Poppins",
    letterSpacing: "0.4px",
    textTransform: "uppercase",
  },
}));

export function RatingDisplay({ title, rating, numRatees }) {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const classes = useStyles({ isDesktop });
  const sharedClasses = useSharedStyles();

  return (
    <div className={classes.ratingFieldContainer}>
      <div className={classes.ratingLabel}>{title}</div>
      <div className={sharedClasses.ratingValueContainer}>
        <StarRating rating={round(rating * 2, 0) / 2} isDesktop={isDesktop} />
        <div className={sharedClasses.ratingsValueNumber}>
          ({numRatees ? round(rating, 2) : "N/A"})
        </div>
      </div>
    </div>
  );
};
