import React, { useContext } from "react";
import round from "lodash/round";

import StarRating from "../partials/StarRating";
import ReadMore from "../../../../General/ReadMore";
import LoginModal from "../LoginModal";
import { PendingApprovalContext } from "../../Show";

function DisplayName({
  anonymous,
  firstName,
  lastName,
  organizationName,
  rfiLiveLink,
}) {
  const userPendingApproval = useContext(PendingApprovalContext);

  return anonymous && !rfiLiveLink ? (
    <div>
      {userPendingApproval ? (
        <div className="font-medium">
          Employer Info shown here pending approval
        </div>
      ) : (
        <div className="cursor-pointer font-medium">
          <LoginModal
            cta={(onClick) => (
              <>
                <span onClick={onClick} className="text-astral underline">
                  Sign in
                </span>{" "}
                to see Employer info
              </>
            )}
            name={anonymous}
          />
        </div>
      )}
    </div>
  ) : (
    <div style={{ fontWeight: 500 }}>
      {firstName || "Verified"} {lastName || "Candidate"}
      {organizationName && ` - ${organizationName}`}
    </div>
  );
}

export default function Review({ review, idx, rfiLiveLink }) {
  const {
    anonymous,
    firstName,
    lastName,
    organizationName,
    rating,
    feedbackNote,
  } = review;

  return (
    <div
      style={{ display: "flex", paddingTop: idx === 0 ? 0 : 32, width: "100%" }}
    >
      <div style={{ width: "100%" }}>
        <div>
          <StarRating rating={round(rating * 2, 0) / 2} isDesktop={true} />
        </div>
        <div style={{ padding: "8px 0px", width: "100%" }}>
          <ReadMore>{feedbackNote}</ReadMore>
        </div>
        <DisplayName
          {...{
            anonymous,
            firstName,
            lastName,
            organizationName,
            rfiLiveLink,
          }}
        />
      </div>
    </div>
  );
}
