import React, { createContext } from "react";
import { useScreenClass, Visible } from "react-grid-system";

import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";

import withGlobalProvidersPrerender from "../../General/withGlobalProvidersPrerender";

import Header from "./show/Header";
import ContactLinks from "./show/ContactLinks";
import RecruiterDescription from "./shared/RecruiterDescription";
import AgencyDescription from "./show/AgencyDescription";
import Statistics from "../../Individuals/show/Statistics/Statistics";
import Overview from "./show/Overview";
import Certifications from "./show/Certifications";
import DiversityFocus from "./shared/DiversityFocus";
import SearchOfferings from "./shared/SearchOfferings";
import Footer from "./shared/Footer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchIndividual } from "../../../actions/individualActions";
import Reviews from "./shared/Reviews";
import RatingsOverview from "./shared/RatingsOverview/Index";
import SearchSpecialties from "./show/SearchSpecialties/SearchSpecialties";
import RecruiterStatistics from "./shared/RecruiterStatistics";

export const PendingApprovalContext = createContext({});

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    alignSelf: "center",
    width: "100%",
    padding: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Poppins",
    fontWeight: 300,
    lineHeight: 1.5,
    marginBottom: 40,
    fontSize: 16,
    overflow: "inherit",
  },
  contentContainer: ({ isDesktop }) => ({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: isDesktop ? theme.spacing(3) : theme.spacing(2),
  }),
  leftContent: ({ isDesktop }) => ({
    display: "flex",
    width: isDesktop ? "70%" : "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingLeft: isDesktop ? "18px" : 0,
    paddingRight: isDesktop ? theme.spacing(4) : theme.spacing(2),
  }),
  rightContent: {
    minWidth: 280,
    width: "30%",
    height: "auto",
    fontFamily: "Poppins",
    fontWeight: 300,
    lineHeight: 1.5,
    marginBottom: 40,
    fontSize: 16,
  },
  rightContentBordered: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    borderLeft: "1px solid black",
    position: "-webkit-sticky",
    position: "sticky",
    height: "auto",
    top: 90,
  },
}));

const Show = ({ individualId, userPendingApproval }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const classes = useStyles({ isDesktop });
  const dispatch = useDispatch();
  const individual = useSelector(
    (state) => state.individuals.items[individualId]
  );

  useEffect(() => {
    dispatch(fetchIndividual(individualId, true));
  }, [dispatch, individualId]);

  if (!individual) return null;

  return (
    <PendingApprovalContext.Provider value={userPendingApproval}>
      <Card classes={{ root: classes.cardContainer }}>
        <div className={classes.contentContainer}>
          <div className={classes.leftContent}>
            <Header {...{ individualId }} />
            <RatingsOverview {...{ individualId, publicEndpoint: true }} />
            <RecruiterStatistics {...{ individualId, publicEndpoint: true }} />
            <RecruiterDescription {...{ individualId }} />
            <AgencyDescription {...{ individualId }} />
            <Visible xs sm md lg>
              <div style={{ margin: "18px 0" }}>
                <ContactLinks {...{ individualId }} />
                <Overview {...{ individualId }} />
                {/* <HiredStatistics {...{ individualId }} /> */}
                <Certifications {...{ individualId }} />
                <DiversityFocus {...{ individualId }} />
                <SearchOfferings {...{ individualId }} />
              </div>
            </Visible>
            <SearchSpecialties {...{ individualId }} />
            <Statistics {...{ individualId, publicEndpoint: true }} />
            <Reviews
              {...{
                individualId,
                endpoint: "employer_reviews",
                title: "Employer",
                publicEndpoint: true,
              }}
            />
            <Reviews
              {...{
                individualId,
                endpoint: "candidate_reviews",
                title: "Candidate",
                publicEndpoint: true,
              }}
            />
          </div>
          <Visible xl xxl>
            <div className={classes.rightContent}>
              <div className={classes.rightContentBordered}>
                <ContactLinks {...{ individualId }} />
                <Overview {...{ individualId }} />
                {/* <HiredStatistics {...{ individualId }} /> */}
                <Certifications {...{ individualId }} />
                <DiversityFocus {...{ individualId }} />
                <SearchOfferings {...{ individualId }} />
              </div>
            </div>
          </Visible>
        </div>
        <Footer />
      </Card>
    </PendingApprovalContext.Provider>
  );
};

export default withGlobalProvidersPrerender(Show);
