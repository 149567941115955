import React, { useEffect, useState } from "react";
import { useScreenClass } from "react-grid-system";

import round from "lodash/round";

import useSharedStyles from "../../useSharedStyles";

import { GET } from "../../../../../util/apiHelpers";

import { mapKeysToCamelCase } from "../../../../../util/formatHelpers";
import CandidateRatings from "./CandidateRatings";
import { EmployerRatings } from "./EmployerRatings";
import StarRating from "../../../../Public/Individuals/shared/partials/StarRating";

export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const ERROR = "ERROR";

export default function Ratings({ individualId, publicEndpoint }) {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);

  const sharedClasses = useSharedStyles({ isDesktop });
  const [cumulativeRatingsData, setCumulativeRatingsData] = useState({});
  const [employerRatingsData, setEmployerRatingsData] = useState({});
  const [candidateRatingsData, setCandidateRatingsData] = useState({});
  const [loadStatus, setLoadStatus] = useState(LOADING);

  useEffect(() => {
    GET(
      "/public/api/v1/individuals/" + individualId + "/aggregate_ratings"
    )
      .then((res) => {
        setCumulativeRatingsData(mapKeysToCamelCase(res.data.cumulative));
        setEmployerRatingsData(mapKeysToCamelCase(res.data.employer));
        setCandidateRatingsData(mapKeysToCamelCase(res.data.candidate));
        setLoadStatus(LOADED);
      })
      .catch(() => {
        setLoadStatus(ERROR);
      });
  }, []);

  const { numRatees } = cumulativeRatingsData;
  const { overall: employerOverall, numRatees: employerNumRatees } =
    employerRatingsData;
  const { overall: candidateOverall, numRatees: candidateNumRatees } =
    candidateRatingsData;

  const combinedRating =
    (employerOverall + candidateOverall) / (employerOverall > 0 ? 2 : 1);

  const overallRatingText = Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(combinedRating);

  return (
    <div className={sharedClasses.ratingWrapper}>
      <div
        className={sharedClasses.overallRatingContainer}
        style={
          isDesktop && (employerNumRatees || candidateNumRatees)
            ? { borderRight: "1px solid" }
            : {}
        }
      >
        <h5 className={sharedClasses.subSectionHeader}>Overall Rating</h5>
        {loadStatus === LOADED && (
          <div style={{ fontSize: 60 }}>
            {numRatees && numRatees > 0 ? overallRatingText : "N/A"}
          </div>
        )}
        <div style={{ minWidth: 125 }}>
          <StarRating
            rating={round(combinedRating * 2, 0) / 2}
            isDesktop={isDesktop}
          />
        </div>
        <div
          style={{
            textAlign: isDesktop ? "right" : "left",
            width: "100%",
            marginTop: 4,
          }}
        >
          {numRatees} rating{numRatees === 1 ? "" : "s"}
        </div>
      </div>
      <div className={sharedClasses.sectionContent}>
        <EmployerRatings
          {...{ loadStatus, employerRatingsData, sharedClasses }}
        />
        <CandidateRatings
          {...{ loadStatus, candidateRatingsData, sharedClasses }}
        />
      </div>
    </div>
  );
}
