import React from "react";

import useSharedStyles from "../useSharedStyles";
import CandidateProgression from "./CandidateProgression";
import Ratings from "./Ratings/Index";

const Statistics = ({ individualId, publicEndpoint }) => {
  const sharedClasses = useSharedStyles();

  return (
    <div
      id="reviews"
      className={sharedClasses.section}
      style={{ paddingTop: 120, marginTop: -120 }}
    >
      <div className={sharedClasses.sectionHeader} style={{ marginTop: 24 }}>
        <div>
          <img
            style={{ display: "inline", height: 42, marginRight: 12 }}
            src={
              "https://recruitifi-public.s3.amazonaws.com/default_avatars/rfi_individual_square.png"
            }
            alt="RecruitiFi-logo"
          />
          RecruitiFi Statistics
        </div>
      </div>
      <div className={sharedClasses.sectionContent}>
        <Ratings {...{ individualId, publicEndpoint }} />
        {/* <CandidateProgression {...{ individualId }} /> */}
      </div>
    </div>
  );
};

export default Statistics;
