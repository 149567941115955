import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useScreenClass } from "react-grid-system";

import makeStyles from "@material-ui/core/styles/makeStyles";

import useSharedStyles from "./useSharedStyles";
import TruncatableContent from "../shared/partials/TruncatableContent";

import LoginModal from "../shared/LoginModal";
import CityIcon from "../../../General/Icons/CityIcon";
import { PendingApprovalContext } from "../Show";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    filter: "blur(5px)",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
  },
  avatarContainer: ({ isDesktop }) => ({
    marginRight: theme.spacing(2),
    width: 135,
    height: 60,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }),
  avatarImage: {
    flexShrink: 0,
    maxWidth: "100%",
    maxHeight: "100%",
  },
  headerTextContainer: {
    display: "flex",
    flexDirection: "column",
    height: 60,
  },
}));

const Header = () => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const classes = useStyles({ isDesktop });
  const sharedClasses = useSharedStyles();

  return (
    <div className={classes.headerContainer}>
      <div unselectable="on" className={classes.avatarContainer}>
        <img
          className={classes.avatarImage}
          src={"https://recruitifi-public.s3.amazonaws.com/Logo.png"}
        />
      </div>
      <div unselectable="on" className={classes.headerTextContainer}>
        <span
          className={sharedClasses.externalLink}
          style={{ marginTop: 4, fontSize: 23.1 }}
        >
          <h5 className={sharedClasses.subSectionHeader}>
            Lorem Ipsum Delano Agency{" "}
            <i className={`icon-xl la la-external-link text-astral-500`}></i>
          </h5>
        </span>
        # team members on RecruitiFi
      </div>
    </div>
  );
};

const AgencyDescription = ({ individualId }) => {
  const individual = useSelector(
    (state) => state.individuals.items[individualId]
  );

  const sharedClasses = useSharedStyles();
  const userPendingApproval = useContext(PendingApprovalContext);

  return (
    <div className={sharedClasses.section} style={{ position: "relative" }}>
      <div className={sharedClasses.sectionHeader}>About the Agency</div>
      <div
        unselectable="on"
        className={sharedClasses.sectionContent}
        style={{
          WebkitUserSelect: "none",
          MozUserSelect: "none",
          msUserSelect: "none",
          userSelect: "none",
          filter: "blur(5px)",
          WebkitFilter: "blur(5px)",
          filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius='5')",
        }}
      >
        <Header />
        <TruncatableContent truncateHeight={300}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac auctor
          augue mauris augue neque gravida in. Vulputate dignissim suspendisse
          in est ante. Ac tortor vitae purus faucibus ornare suspendisse. Nibh
          tortor id aliquet lectus proin nibh nisl. Aliquam vestibulum morbi
          blandit cursus risus at ultrices mi tempus. Cras sed felis eget velit
          aliquet sagittis id consectetur purus.
        </TruncatableContent>
      </div>
      {userPendingApproval ? (
        <div className="flex items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold blur-none">
          <CityIcon size={40} style={{ marginRight: 20, fontSize: 70 }} />
          <div>Agency info shown here pending approval</div>
        </div>
      ) : (
        <LoginModal
          cta={(onClick) => (
            <div
              onClick={onClick}
              className="flex items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold cursor-pointer blur-none"
            >
              <CityIcon size={40} style={{ marginRight: 20, fontSize: 70 }} />
              <div>
                <span className="text-astral underline">Sign in</span> to see
                information about this Agency
              </div>
            </div>
          )}
          name={individual.attributes.publicName}
        />
      )}
    </div>
  );
};

export default AgencyDescription;
