import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";

import times from "lodash/times";
import classnames from "classnames";

const useStyles = makeStyles({
  starIcon: ({ fontSize }) => ({
    color: "#FFAE28",
    lineHeight: `${fontSize}px`,
    fontSize: fontSize,
    verticalAlign: "middle",
    marginTop: -8,
  }),
  emptyStar: {
    color: "#48465B",
  },
});

const StarRating = ({ rating, fontSize = 25}) => {
  const classes = useStyles({ fontSize });
  let currRating = rating;
  const stars = [];

  times(5, (i) => {
    if (currRating >= 1) {
      stars.push(
        <i
          key={`full${i}`}
          className={classnames("icon-xl", "la la-star", classes.starIcon)}
        />
      );
    } else if (currRating >= 0.5) {
      stars.push(
        <i
          key={`half${i}`}
          className={classnames(
            "icon-xl",
            "la la-star-half-alt",
            classes.starIcon
          )}
        />
      );
    } else {
      stars.push(
        <i
          key={`empty${i}`}
          className={classnames(
            "icon-xl",
            "la la-star-o",
            classes.starIcon,
            classes.emptyStar
          )}
        />
      );
    }
    currRating--;
  });

  return <div>{stars}</div>;
};

export default StarRating;
