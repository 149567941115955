import { RECEIVE_INDIVIDUALS } from "../actions/individualActions";
import merge from "lodash/merge";

const defaultState = {
  items: {},
  loaded: false,
};

const individualsReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_INDIVIDUALS:
      return merge({}, state, { items: action.payload, loaded: true });
    default:
      return state;
  }
};

export default individualsReducer;
