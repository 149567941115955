import React from "react";

import MUIButton from "@material-ui/core/Button";

const Button = (props) => <MUIButton {...props} />;

Button.defaultProps = {
  color: "primary",
  variant: "contained",
};

export default Button;
