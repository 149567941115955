import React, { useContext } from "react";
import round from "lodash/round";

import { AvatarIcon } from "../../../../General/AvatarIcon";
import ReadMore from "../../../../General/ReadMore";
import StarRating from "../../../../Public/Individuals/shared/partials/StarRating";
import LoginModal from "../../../../Public/Individuals/shared/LoginModal";
import { createUseStyles } from "react-jss";
import { PendingApprovalContext } from "../../Show";

export const styles = createUseStyles({
  organizationName: {
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "Poppins",
    lineHeight: "2.28571rem",
  },
});

function DisplayName({ anonymous, first_name, last_name, rfiLiveLink }) {
  const userPendingApproval = useContext(PendingApprovalContext);

  return anonymous && !rfiLiveLink ? (
    <div style={{ fontWeight: 500, textAlign: "center" }}>
      {userPendingApproval ? (
        <div className="font-medium text-center mt-2">
          Employer Info shown here pending approval
        </div>
      ) : (
        <div className="cursor-pointer font-medium text-center mt-2">
          <LoginModal
            cta={(onClick) => (
              <>
                <span onClick={onClick} className="text-astral underline">
                  Sign in
                </span>{" "}
                to see<div>Employer info</div>
              </>
            )}
            name={anonymous}
          />
        </div>
      )}
    </div>
  ) : (
    <div style={{ fontWeight: 500, textAlign: "center", marginTop: 8 }}>
      {first_name || "Verified"} {last_name || "Candidate"}
    </div>
  );
}

export default function Review({ review, idx, rfiLiveLink }) {
  const {
    individual_avatar,
    first_name,
    last_name,
    organization_name,
    rating,
    feedback_note,
    anonymous,
  } = review;

  const member = {
    attributes: {
      firstName: first_name || "Verified",
      lastName: last_name || "Candidate",
      avatarUrl: first_name
        ? individual_avatar
        : "https://recruitifi-public.s3.amazonaws.com/default_avatars/rfi_logo_square.svg",
    },
  };

  return (
    <div style={{ display: "flex", paddingTop: idx === 0 ? 0 : 32 }}>
      <div style={{ width: 200 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <AvatarIcon user={member} height={80} />
        </div>
        <DisplayName
          {...{
            anonymous,
            first_name,
            last_name,
            rfiLiveLink,
          }}
        />
        {organization_name && (
          <div style={{ textAlign: "center" }}>{organization_name}</div>
        )}
      </div>
      <div
        style={{ width: "calc(100% - 200px)", paddingLeft: 12, marginTop: 16 }}
      >
        <div>
          <StarRating rating={round(rating * 2, 0) / 2} isDesktop={true} />
        </div>
        <div style={{ padding: "8px 0px" }}>
          <ReadMore>{feedback_note}</ReadMore>
        </div>
      </div>
    </div>
  );
}
