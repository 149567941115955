import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useScreenClass } from "react-grid-system";
import AddIcon from "@material-ui/icons/Add";

import HtmlTooltip from '../../../../../General/HtmlTooltip';
import LoginModal from "../../../shared/LoginModal";
import withGlobalProviders from "../../../../../General/withGlobalProviders";

function ActivateButton({ id }) {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const individual = useSelector(
    (state) => state.individuals.items[id],
    shallowEqual
  );

  return (
    <LoginModal cta={(onClick) => (
      <HtmlTooltip
        title={
          <React.Fragment>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                fontSize: 14,
              }}
            >
              Add to saved recruiters
            </div>
          </React.Fragment>
        }
      >
        <button
          style={{
            height: 34,
            display: "block",
            float: "right",
            width: isDesktop ? "100%" : 160,
            lineHeight: "19px"
          }}
          className="btn btn-sm btn-outline-brand-secondary"
          onClick={onClick}
        >
          <AddIcon style={{ marginTop: -2 }} fontSize={"small"} /> Save
        </button>
      </HtmlTooltip>
    )}
      name={individual.attributes.publicName}
    />
  );
}

export default withGlobalProviders(ActivateButton);
