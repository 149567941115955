import React, { useEffect, useState } from "react";
import { useScreenClass } from "react-grid-system";
import sortBy from "lodash/sortBy";

import round from "lodash/round";
import map from "lodash/map";

import useSharedStyles from "../../../../Individuals/show/useSharedStyles";

import { GET } from "../../../../../util/apiHelpers";

import { mapKeysToCamelCase } from "../../../../../util/formatHelpers";
import StarRating from "../partials/StarRating";
import Review from "./Review";

export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const ERROR = "ERROR";

export default function RatingsOverview({
  individualId,
  publicEndpoint,
  rfiLiveLink,
}) {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);

  const sharedClasses = useSharedStyles({ isDesktop });
  const [cumulativeRatingsData, setCumulativeRatingsData] = useState({});
  const [employerRatingsData, setEmployerRatingsData] = useState({});
  const [candidateRatingsData, setCandidateRatingsData] = useState({});
  const [loadStatus, setLoadStatus] = useState(LOADING);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    GET(
      "/public/api/v1/individuals/" + individualId + "/aggregate_ratings_and_reviews",
      rfiLiveLink ? { rfiLiveLink } : {}
    )
      .then((res) => {
        setCumulativeRatingsData(mapKeysToCamelCase(res.data.cumulative));
        setEmployerRatingsData(mapKeysToCamelCase(res.data.employer));
        setCandidateRatingsData(mapKeysToCamelCase(res.data.candidate));
        setReviews(mapKeysToCamelCase(res.data.reviews));
        setLoadStatus(LOADED);
      })
      .catch(() => {
        setLoadStatus(ERROR);
      });
  }, []);

  const { numRatees } = cumulativeRatingsData;
  const { overall: employerOverall, numRatees: employerNumRatees } =
    employerRatingsData;
  const { overall: candidateOverall, numRatees: candidateNumRatees } =
    candidateRatingsData;

  const combinedRating =
    (employerOverall + candidateOverall) / (employerOverall > 0 ? 2 : 1);

  if (!reviews || Object.keys(reviews).length === 0) return null;

  const overallRatingText = Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(combinedRating);

  return (
    <div className={sharedClasses.ratingWrapper}>
      <div
        className={sharedClasses.overallRatingContainer}
        style={
          isDesktop && (employerNumRatees || candidateNumRatees)
            ? { borderRight: "1px solid" }
            : {}
        }
      >
        <h5 className={sharedClasses.subSectionHeader}>Overall Rating</h5>
        {loadStatus === LOADED && (
          <div style={{ fontSize: 60 }}>
            {numRatees && numRatees > 0 ? overallRatingText : "N/A"}
          </div>
        )}
        <div style={{ minWidth: 125 }}>
          <StarRating
            rating={round(combinedRating * 2, 0) / 2}
            isDesktop={isDesktop}
          />
        </div>
        <a
          href={"#reviews"}
          className={"text-astral-500 underline"}
          style={{
            textAlign: isDesktop ? "right" : "left",
            width: "100%",
            textDecoration: "underline",
            color: "#357BA6",
            marginTop: 4,
          }}
        >
          {numRatees} rating{numRatees === 1 ? "" : "s"}
        </a>
      </div>
      <div
        className={sharedClasses.sectionContent}
        style={{ minWidth: "100% !important" }}
      >
        <h5 className={sharedClasses.subSectionHeader}>Recent Reviews</h5>
        <div
          className={sharedClasses.sectionContent}
          style={{ minWidth: "100% !important" }}
        >
          {map(
            sortBy(reviews, (review) => review.order),
            (review, idx) => (
              <Review
                key={review.id}
                review={review}
                idx={idx}
                rfiLiveLink={rfiLiveLink}
              />
            )
          )}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: 18,
            }}
          >
            <a
              style={{
                height: 34,
                margin: "0 auto",
                width: 160,
                lineHeight: "19px",
              }}
              className="btn btn-sm btn-outline-brand-secondary"
              href={"#reviews"}
            >
              Read More Reviews
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
