import React from "react";

import PaginationLinks from "../../../General/PaginationLinks";

export default function PaginationSection({
  loading,
  totalPages,
  currentPage,
  handlePageSelection,
}) {
  if (loading && totalPages <= 1) return null;

  return (
    <div
      className="kt-portlet kt-portlet--height-fluid"
      style={{ height: "auto" }}
    >
      <div
        className="kt-portlet__body kt-portlet__body--fit"
        style={{ padding: 20 }}
      >
        <PaginationLinks
          currPage={currentPage}
          numPages={totalPages}
          handleSelection={handlePageSelection}
        />
      </div>
    </div>
  );
}
