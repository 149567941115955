import React from "react";

import { connect, FieldArray, ErrorMessage, useFormikContext } from "formik";
import TextField from "./TextField";
import Textarea from "./Textarea";
import FiveStarRadio from "./FiveStarRadio";
import Dropdown from "./Dropdown";
import Switch from "./Switch";
import Wysiwyg from "./Wysiwyg/Wysiwyg";
import DatePicker from "./DatePicker";
import TimePicker from "./TimePicker";
import MultiToggle from "./MultiToggle";
import FileInput from "./FileInput";
import map from "lodash/map";
import Select from "react-select";

import get from "lodash/get";
import filter from "lodash/filter";

export const StyledErrorMessage = ({ style, ...props }) => (
  <ErrorMessage
    {...props}
    render={(msg) => (
      <div
        style={Object.assign(
          {},
          { color: "#f45b5e", marginBottom: "-18px" },
          style
        )}
      >
        {msg}
      </div>
    )}
  />
);

const CharCountMessage = ({ maxCharacters, value }) => {
  if (!maxCharacters) {
    return null;
  }

  const currLength = (value && value.length) || 0;

  return (
    <div
      style={{
        float: "right",
        color: currLength > maxCharacters ? "#f45b5e" : "#495057",
      }}
    >
      {currLength} / {maxCharacters}
    </div>
  );
};

export const ConnectedTextField = connect(
  ({
    formik: { values, handleChange, handleBlur, errors, touched },
    id,
    maxCharacters,
    ...props
  }) => {
    const value = get(values, id);
    const showError = Boolean(get(touched, id) && get(errors, id));

    return (
      <TextField
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        showError={showError}
        helperText={
          <React.Fragment>
            <StyledErrorMessage name={id} />
            <CharCountMessage maxCharacters={maxCharacters} value={value} />
          </React.Fragment>
        }
        {...props}
      />
    );
  }
);

export const ConnectedStandardInput = connect(
  ({
    formik: {
      values,
      handleChange,
      handleBlur,
      errors,
      touched,
      setFieldValue,
    },
    InputField,
    id,
    maxCharacters,
    ...inputFieldProps
  }) => {
    const value = get(values, id);
    const showError = Boolean(get(touched, id) && get(errors, id));

    return (
      <InputField
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        showError={showError}
        setValue={(value) => setFieldValue(id, value)}
        helperText={
          <React.Fragment>
            <StyledErrorMessage name={id} />
            <CharCountMessage maxCharacters={maxCharacters} value={value} />
          </React.Fragment>
        }
        {...inputFieldProps}
      />
    );
  }
);

export const ConnectedTextarea = (props) => (
  <ConnectedStandardInput InputField={Textarea} {...props} />
);

export const ConnectedDropdown = connect(
  ({
    formik: { values, setFieldValue, setFieldTouched, errors, touched },
    id,
    onChange,
    ...props
  }) => {
    const value = get(values, id);
    const showError = Boolean(get(touched, id) && get(errors, id));
    const handleChange = (selectedOption) => {
      setFieldValue(id, selectedOption.value);
      onChange && onChange(selectedOption);
    };
    const handleBlur = () => {
      setFieldTouched(id, true);
    };

    return (
      <Dropdown
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        showError={showError}
        helperText={<StyledErrorMessage name={id} />}
        {...props}
      />
    );
  }
);

export const ConnectedFiveStarRadio = (props) => (
  <ConnectedStandardInput InputField={FiveStarRadio} {...props} />
);

export const ConnectedSwitch = (props) => (
  <ConnectedStandardInput InputField={Switch} {...props} />
);

export const ConnectedSelect = connect(
  ({
    formik: { values, setFieldValue, handleBlur, errors, touched },
    InputField,
    id,
    label,
    options,
    placeholder,
    removeFormGroupMargin = false,
    ...inputFieldProps
  }) => {
    const showError = Boolean(get(touched, id) && get(errors, id));

    const onChange = (option) => {
      setFieldValue(
        id,
        inputFieldProps.isMulti
          ? map(option, (item) => item.value)
          : option.value
      );
    };

    const getValue = () => {
      if (options) {
        return inputFieldProps.isMulti
          ? options.filter(
              (option) => get(values, id).indexOf(option.value) >= 0
            )
          : options.find((option) => option.value === get(values, id));
      } else {
        return inputFieldProps.isMulti ? [] : "";
      }
    };

    const customStyles = {
      singleValue: (base) => ({
        ...base,
        color: "#495057",
        fontSize: "1.2857142857142858rem",
        lineHeight: "2.2857142857142856rem",
        fontWeight: 400,
      }),
      multiValue: (base) => ({
        ...base,
        paddingLeft: 5,
        fontSize: "1.2857142857142858rem",
        lineHeight: "2.2857142857142856rem",
      }),
      placeholder: (base) => ({
        ...base,
        fontSize: "1.2857142857142858rem",
        color: "#92959A",
        lineHeight: "2.2857142857142856rem",
        fontWeight: 400,
      }),
      valueContainer: (base) => ({
        ...base,
        lineHeight: "2.2857142857142856rem",
      }),
      option: (base, { isSelected, isFocused }) => ({
        ...base,
        backgroundColor: isSelected
          ? isFocused
            ? "#1A3C51"
            : "#357BA6"
          : isFocused
          ? "#e2e8ea"
          : "inherit",
        "&:hover": {
          backgroundColor: isSelected ? "#357BA6" : "#e2e8ea",
        },
      }),
      control: (base, state) => {
        let controlStyle = {
          ...base,
          borderColor: "#e2e5ec",
          boxShadow: "0 0 0 0 #357BA6 !important",
          fontSize: 14,
        };

        if (state.isFocused) {
          controlStyle = {
            ...base,
            borderColor: "#357BA6 !important",
            boxShadow: "0 0 0 0 #357BA6 !important",
            "&:focus": {
              borderColor: "#357BA6 !important",
              boxShadow: "0 0 0 0 #357BA6 !important",
            },
            "&:active": {
              borderColor: "#357BA6 !important",
              boxShadow: "0 0 0 0 #357BA6 !important",
            },
          };
        } else if (state.selectProps.isError) {
          controlStyle = {
            ...base,
            borderColor: "#357BA6 !important",
            boxShadow: "0 0 0 0 #357BA6 !important",
            "&:focus": {
              borderColor: "#357BA6 !important",
              boxShadow: "0 0 0 0 #357BA6 !important",
            },
            "&:active": {
              borderColor: "#357BA6 !important",
              boxShadow: "0 0 0 0 #357BA6 !important",
            },
          };
        }

        return controlStyle;
      },
    };

    return (
      <div
        className="form-group"
        style={removeFormGroupMargin ? { margin: 0 } : {}}
      >
        {label && (
          <>
            <label
              htmlFor={id}
              style={{
                fontSize: "1.2857142857142858rem",
                lineHeight: "2.2857142857142856rem",
                fontWeight: 400,
                ...(showError ? { color: "#f45b5e" } : {}),
              }}
            >
              {label}
            </label>
            <br />
          </>
        )}
        <Select
          placeholder={placeholder || null}
          options={options}
          value={getValue()}
          onBlur={handleBlur}
          onChange={onChange}
          aria-describedby={`${id}-helper-text`}
          styles={customStyles}
          isError={showError}
          closeMenuOnSelect={!inputFieldProps.isMulti}
          {...inputFieldProps}
        />
        <StyledErrorMessage name={id} />
      </div>
    );
  }
);

export const ConnectedDatePicker = connect(
  ({
    formik: { values, errors, touched, handleBlur, setFieldValue },
    id,
    start_id,
    end_id,
    ...props
  }) => {
    const handleChange = (momentObject) => {
      const formatted_date =
        momentObject &&
        momentObject.format(
          props.dateTime ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"
        );
      setFieldValue(id, formatted_date);
      start_id &&
        setFieldValue(
          start_id,
          `${formatted_date} ${moment(values[start_id]).format("HH:mm")}`
        );
      end_id &&
        setFieldValue(
          end_id,
          `${formatted_date} ${moment(values[end_id]).format("HH:mm")}`
        );
    };
    const value = get(values, id);
    const showError = Boolean(get(touched, id) && get(errors, id));

    return (
      <DatePicker
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        showError={showError}
        helperText={<StyledErrorMessage name={id} />}
        {...props}
      />
    );
  }
);

export const ConnectedTimePicker = connect(
  ({
    formik: { values, errors, touched, handleBlur, setFieldValue },
    id,
    ...props
  }) => {
    const handleChange = (e) => {
      setFieldValue(id, moment && `${values.date} ${e.target.value}`);
    };

    const value = get(values, id);
    const showError = Boolean(get(touched, id) && get(errors, id));

    return (
      <TimePicker
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        showError={showError}
        helperText={<StyledErrorMessage name={id} />}
        {...props}
      />
    );
  }
);

export const ConnectedWysiwyg = connect(
  ({
    formik: { values, errors, handleBlur, setFieldValue, setTouched, touched },
    id,
    showErrorOnBlur,
    ...props
  }) => {
    const handleChange = (content) => {
      setFieldValue(id, content);
    };
    const onBlur = (e) => {
      handleBlur(e);
      showErrorOnBlur && setTouched({ [id]: true });
    };

    const value = get(values, id);
    const showError = Boolean(get(touched, id) && get(errors, id));

    return (
      <Wysiwyg
        id={id}
        onChange={handleChange}
        onBlur={onBlur}
        value={value}
        showError={showError}
        helperText={<StyledErrorMessage name={id} />}
        {...props}
      />
    );
  }
);

export const ConnectedInputList = connect(
  ({
    formik: { values },
    id,
    minItems = 1,
    maxItems = 10,
    renderInputRow = (_value, index) => (
      <ConnectedTextField id={`${id}.${index}`} />
    ),
    createNewValue = () => "",
    styles = { container: {}, row: {} },
    ...props
  }) => (
    <FieldArray
      name={id}
      render={(arrayHelpers) => (
        <div
          style={Object.assign(
            {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "2rem",
              width: "100%",
            },
            styles.container
          )}
        >
          {values[id].map((value, index) => (
            <div
              style={Object.assign(
                {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                },
                styles.row
              )}
              key={index}
            >
              {renderInputRow(value, index)}
              {minItems && index + 1 > minItems && (
                <button
                  className="btn font-weight-bold btn-brand btn-icon"
                  style={{ marginLeft: 15 }}
                  onClick={() => arrayHelpers.remove(index)}
                >
                  <i className="la la-remove"></i>
                </button>
              )}
            </div>
          ))}
          {maxItems && values[id].length < maxItems && (
            <button
              className="btn font-weight-bold btn-secondary"
              onClick={() => arrayHelpers.push(createNewValue())}
            >
              <i className="la la-plus"></i>
              Add
            </button>
          )}
        </div>
      )}
    />
  )
);

export const ConnectedMultiToggle = connect(
  ({
    formik: { values, errors, handleBlur, setFieldValue, touched },
    id,
    ...props
  }) => {
    const value = get(values, id);
    const handleChange = (e) => {
      const contentToggled = e.currentTarget.checked;
      const contentValue = e.currentTarget.name;

      contentToggled
        ? setFieldValue(id, value.concat(contentValue))
        : setFieldValue(
            id,
            filter(value, (item) => item !== contentValue)
          );
    };
    const showError = Boolean(get(touched, id) && get(errors, id));

    return (
      <MultiToggle
        id={id}
        onChange={handleChange}
        onBlur={handleBlur}
        checkedValues={value}
        showError={showError}
        helperText={<StyledErrorMessage name={id} />}
        {...props}
      />
    );
  }
);

export const ConnectedFileInput = connect(
  ({
    formik: { values, errors, handleBlur, setFieldValue, touched },
    id,
    ...props
  }) => {
    const handleChange = (content) => setFieldValue(id, content);
    const value = get(values, id);
    const showError = Boolean(get(touched, id) && get(errors, id));

    return (
      <FileInput
        id={id}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        showError={showError}
        helperText={<StyledErrorMessage name={id} />}
        {...props}
      />
    );
  }
);
