import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import { DESC_RATING } from "./index/order/orderHelpers";

import { fetchIndividuals as fetchFunction } from "../../actions/individualActions";

import withGlobalProviders from "../General/withGlobalProviders";
import PaginatedFetch from "../General/PaginatedFetch";

import IndexComponent from "./index/IndexComponent";

function Index() {
  let location = useLocation();

  const queryParams = queryString.parse(location.search);

  const [search, setSearch] = useState(queryParams.search || "");
  const [order, setOrder] = useState(queryParams.order || DESC_RATING);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [customParams, setCustomParams] = useState({});

  useEffect(() => {
    if (!loading) {
      const queryParams = queryString.parse(location.search);

      if (search !== queryParams.search) {
        setSearch(queryParams.search || "");
      }
    }
  }, [location]);

  useEffect(() => {
    setCustomParams({ order, search, ...filters });
  }, [order, search, filters]);

  return (
    <PaginatedFetch
      {...{
        fetchFunction,
        loading,
        setLoading,
        customParams,
        initialPage: (queryParams.page && parseInt(queryParams.page)) || 1,
      }}
    >
      {({ fetchAction, handlePageSelection, currentPage, totalPages, items, loading }) => (
        <IndexComponent
          {...{
            fetchAction,
            handlePageSelection,
            currentPage,
            totalPages,
            search,
            setSearch,
            order,
            setOrder,
            loading,
            setLoading,
            setFilters,
            items,
          }}
        />
      )}
    </PaginatedFetch>
  );
};

export default withGlobalProviders(Index);
