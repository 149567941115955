import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Col, Visible } from "react-grid-system";
import { AvatarIcon } from "../../../../General/AvatarIcon";

import { styles, Name, OrganizationName } from "./utils";
import StarRatingDisplay from "../../../../Vendors/general/StarRatingDisplay";

export default function AvatarColumn({ id }) {
  const individual = useSelector(
    (state) => state.individuals.items[id],
    shallowEqual
  );

  const classes = styles();

  const { firstName, lastName, publicName, featured } = individual.attributes;

  return (
    <React.Fragment>
      <Col
        xs={4}
        lg={2}
        className={classes.avatarColumn}
        style={{ maxWidth: 120, flexDirection: "column" }}
      >
        <AvatarIcon user={individual} height={90} />
        {featured && (
          <div
            style={{
              marginTop: 4,
              color: "#90A4AE",
              fontSize: 12,
              fontWeight: 600,
              letterSpacing: -0.25,
              fontFamily: "'Poppins', helvetica",
              textTransform: "capitalize",
              lineHeight: "16px",
              textAlign: "center",
            }}
          >
            AGENCY OF
            <br />
            THE DAY
          </div>
        )}
      </Col>
      <Visible xs sm md>
        <Col xs={8} className={classes.mobileName}>
          <Name {...{ firstName, lastName, id }} />
          <br />
          <OrganizationName individualName={publicName} />
          <br />
          <StarRatingDisplay rating={4.5} customInfo={" (4.5) by candidates"} />
        </Col>
      </Visible>
    </React.Fragment>
  );
}
