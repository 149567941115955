import React from "react";

export default function RecruiterCTA({ recruitifiRegistrationUrl }) {
  return (
    <div className="bg-gray-100">
      <div className="kt-container py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between" style={{ maxWidth: 700}}>
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">Agency Recruiter?</span>
          <span className="block text-astral-500">List your profile today.</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <a
              href="/for-agencies"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-xl uppercase font-normal rounded-md text-white bg-astral-500 hover:bg-astral-600"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
