import React  from "react";

export default function Explainer() {
  return (
    <div className="bg-white pt-12 pb-24">
      <div className="kt-container">
        <div className="w-full">
          <div className="w-full text-4xl tracking-wide font-medium text-gray-800 sm:text-4xl mb-3 inline-flex justify-center">
            <h2>Talk to the right specialized recruiters in minutes</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-9">
            <div className="col-span-1 flex flex-col items-center md:items-start">
              <img style={{ marginBottom: 8 }} height={"456"} width={"415"} src="/assets/media/misc/how-it-works-1.png" />
              <p className="text-3xl text-gray-700 font-medium text-center px-6 mt-12">
                Easily search for Agency Recruiters based on your needs.
              </p>
            </div>
            <div className="col-span-1 flex flex-col items-center md:items-start">
              <img style={{ marginTop: 73 }} height={"382"} width={"415"} src="/assets/media/misc/how-it-works-2.jpg" />
              <p className="text-3xl text-gray-700 font-medium text-center px-6 mt-12">
                Confidently select the best recruiters using reviews and verified placements.
              </p>
            </div>
            <div className="col-span-1 flex flex-col items-center md:items-start">
              <img height={"456"} width={"415"} src="/assets/media/misc/how-it-works-3.png" />
              <p style={{ marginTop: 36 }} className="text-3xl text-gray-700 font-medium text-center px-6 mt-12">
                Contact, contract, and receive candidates in days.
              </p>
            </div>
            <div className="col-span-full flex justify-center">
              <a
                href="/search"
                className="inline-flex items-center justify-center shadow-lg px-5 py-3 border border-transparent text-xl uppercase font-normal rounded-md text-white bg-astral-500 hover:bg-astral-600"
              >
                Find Recruiters
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
