import React from "react";
import { useSelector } from "react-redux";
import { useScreenClass } from "react-grid-system";
import AddIcon from "@material-ui/icons/Add";

import makeStyles from "@material-ui/core/styles/makeStyles";

import useSharedStyles from "./useSharedStyles";
import Button from "../shared/partials/Button";
import MUIButton from "@material-ui/core/Button";

import classnames from "classnames";
import LoginModal from "../shared/LoginModal";

const useStyles = makeStyles((theme) => ({
  container: ({ isDesktop }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: isDesktop ? "flex-start" : "center",
  }),
  linkButtonRoot: ({ isDesktop }) => ({
    textTransform: "none",
    margin: isDesktop ? "8px 0" : theme.spacing(1),
    width: 200,
    fontSize: 16,
  }),
  linkDataContainer: {
    height: 40,
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1),
  },
}));

const ContactLinks = ({ individualId }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const classes = useStyles({ isDesktop });
  const sharedClasses = useSharedStyles({ isDesktop });

  const individual = useSelector(
    (state) => state.individuals.items[individualId]
  );

  return (
    <div className={classnames(classes.container, sharedClasses.rightSection)}>
      <LoginModal cta={(onClick) => (
        <React.Fragment>
          <Button
            onClick={onClick}
            classes={{
              root: classes.linkButtonRoot,
            }}
          >
            Contact Info
          </Button>
          <MUIButton
            color={"primary"}
            variant={"outlined"}
            onClick={onClick}
            style={{
              display: "block",
              float: "right",
            }}
            className={classes.linkButtonRoot}
          >
            <AddIcon fontSize={"small"} /> Save
          </MUIButton>
        </React.Fragment>
      )}
        name={individual.attributes.publicName}
      />
    </div>
  );
};

export default ContactLinks;
