import React, { useState, useRef } from "react";

import normalize from "json-api-normalizer";
import map from "lodash/map";
import round from "lodash/round";

import { LocationMarkerIcon, LibraryIcon } from "@heroicons/react/solid";
import {
  ClockIcon,
  BriefcaseIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";

import { AvatarIcon } from "../../../General/AvatarIcon";
import StarRating from "../shared/partials/StarRating";

import { us_states, phone_countries } from "../../../Individuals/show/constants";

function RecruiterCard({ recruiter, idx }) {
  const {
    firstName,
    lastName,
    overallRating,
    state,
    countryCode,
    yearsExperience,
    matchPreferences,
  } = recruiter.attributes;

  const { jobCastRoles, majorIndustries } = matchPreferences;

  let locationString;

  if (countryCode === "US") {
    locationString = us_states[state] ? `${us_states[state]}, ${countryCode}` : "United States"
  } else if (phone_countries[countryCode] && phone_countries[countryCode].name) {
    locationString = phone_countries[countryCode].name
  }

  return (
    <a
      href={"/" + recruiter.id}
      className={"inline-block" + (idx === 0 ? " ml-3 pr-3" : " px-3")}
    >
      <div className="w-112 overflow-hidden rounded-xl shadow-md bg-white hover:shadow-xl cursor-pointer transition-shadow duration-300 ease-in-out p-4">
        <div className="flex items-center">
          <AvatarIcon user={recruiter} height={90} />
          <div className="ml-4">
            <p className="text-3xl font-medium text-astral-500 md:text-3xl hover:underline">
              {firstName} {lastName}
            </p>
            <div className="flex text-lg items-center mt-3">
              <StarRating rating={round(overallRating * 2, 0) / 2} />
              <div className="ml-2 text-gray-700">
                ({overallRating !== 0 ? round(overallRating, 2) : "N/A"})
              </div>
            </div>
          </div>
        </div>
        <div className="w-full grid grid-cols-2 gap-4 mt-4">
          <div className="text-xl text-gray-600 flex items-center">
            <LocationMarkerIcon
              className="h-6 w-6 mr-2 -mt-0.5"
              aria-hidden="true"
            />
            {locationString}
          </div>
          <div className="text-xl text-gray-600 flex items-center">
            <BriefcaseIcon
              className="h-6 w-6 mr-2 -mt-0.5"
              aria-hidden="true"
            />
            {jobCastRoles.length} Functional Roles
          </div>
          <div className="text-xl text-gray-600 flex items-center">
            <ClockIcon className="h-6 w-6 mr-2 -mt-0.5" aria-hidden="true" />
            {yearsExperience} Year{yearsExperience !== 1 && "s"} Experience
          </div>
          <div className="text-xl text-gray-600 flex items-center">
            <LibraryIcon className="h-6 w-6 mr-2 -mt-0.5" aria-hidden="true" />
            {majorIndustries.length}{" "}
            {majorIndustries.length === 1 ? "Industry" : "Industries"}
          </div>
        </div>
      </div>
    </a>
  );
}

const rightBoxShadow = "rgb(136 136 136 / 40%) 12px 0px 12px -12px";

export default function RecruiterCardCarousel({
  title,
  subtitle,
  first = false,
  recruiters = { data: [] },
}) {
  const normalizedIndividuals = Object.values(normalize(recruiters).individual);
  const cardContainerRef = useRef(null);
  const [rightShadow, setRightShadow] = useState(true);

  function handleScroll(e) {
    const { offsetLeft, scrollLeft, offsetWidth } = e.currentTarget;
    const left = offsetLeft + scrollLeft + offsetWidth;

    if (left >= cardContainerRef.current.scrollWidth) {
      setRightShadow(false);
    } else if (left < cardContainerRef.current.scrollWidth) {
      setRightShadow(true);
    }
  }

  const scroll = (direction) => {
    if (direction === "left") {
      cardContainerRef.current.scrollLeft -= 480;
    } else {
      cardContainerRef.current.scrollLeft += 480;
    }
  };

  return (
    <div className="bg-gray-100">
      <div className={"kt-container" + (first ? " pt-32" : " py-24")}>
        <h2 className="text-4xl tracking-wide font-medium text-gray-800 sm:text-4xl">
          {title}
        </h2>
        <p className="text-2xl text-gray-600 mt-3">{subtitle}</p>
        <div
          className="flex overflow-x-scroll py-10 -ml-3"
          style={{
            boxShadow: rightShadow ? rightBoxShadow : "",
            scrollBehavior: "smooth",
          }}
          onScroll={handleScroll}
          ref={cardContainerRef}
        >
          {map(normalizedIndividuals, (recruiter, idx) => (
            <RecruiterCard {...{ recruiter, idx, key: recruiter.id }} />
          ))}
        </div>
        <div className="mt-1.5" style={{ maxWidth: 138 }}>
          <button
            type="button"
            onClick={() => scroll("left")}
            className="mr-8 inline-flex items-center p-3 border border-astral rounded-full hover:shadow-lg shadow-md text-astral-500 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-astral-500 select-none"
          >
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
          </button>
          <button
            type="button"
            onClick={() => scroll("right")}
            className="inline-flex items-center p-3 border border-astral rounded-full hover:shadow-lg shadow-md text-astral-500 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-astral-500 select-none"
          >
            <ChevronRightIcon className="h-8 w-8" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}
