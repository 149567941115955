export const ASC_NAME = "asc_name";
export const DESC_NAME = "desc_name";
export const DESC_RATING = "desc_rating";
export const DEFAULT = "default";

export const AGENCIES_SORT_OPTIONS = [
  {
    value: DESC_RATING,
    label: "Highest Rated",
  },
  {
    value: DEFAULT,
    label: "Relevance",
    defaultValue: true,
  },
  {
    value: ASC_NAME,
    label: "A to Z",
  },
  {
    value: DESC_NAME,
    label: "Z to A",
  },
];

export const defaultValue = {
  value: DEFAULT,
  label: "Default Sort",
  defaultValue: true,
};
