import React, { useContext } from "react";

import { useSelector } from "react-redux";

import CityIcon from "../../../General/Icons/CityIcon";
import { PendingApprovalContext } from "../Show";
import useSharedStyles from "../show/useSharedStyles";
import LoginModal from "./LoginModal";

import RecruiterStatisticsData from "./partials/RecruiterStatisticsData";
import TruncatableContent from "./partials/TruncatableContent";

const RecruiterStatistics = ({ individualId, publicEndpoint }) => {
  const userPendingApproval = useContext(PendingApprovalContext);
  const sharedClasses = useSharedStyles();
  const individual = useSelector(
    (state) => state.individuals.items[individualId]
  );

  const {
    cachedTopRole,
    cachedTopIndustry,
    cachedSecondRole,
    cachedTopLocation,
  } = individual.attributes;

  if (
    !cachedTopRole &&
    !cachedTopIndustry &&
    !cachedSecondRole &&
    !cachedTopLocation
  )
    return null;

  return (
    <div className={sharedClasses.section} style={{ paddingTop: "10px" }}>
      <div className={sharedClasses.sectionHeader}>Recent Placements</div>
      <div className={sharedClasses.statisticHeader}>
        Placements verified by
        <img
          style={{ display: "inline", height: 20, margin: 5 }}
          src={"https://recruitifi-public.s3.amazonaws.com/TALK-rfi-logo.png"}
          alt="RecruitiFi-logo"
        />
      </div>
      {publicEndpoint ? (
        <div style={{ position: "relative" }}>
          <div
            unselectable="on"
            className={sharedClasses.sectionContent}
            style={{
              WebkitUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
              filter: "blur(5px)",
              WebkitFilter: "blur(5px)",
              filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius='5')",
            }}
          >
            <TruncatableContent truncateHeight={300}>
              <RecruiterStatisticsData
                {...{
                  cachedTopRole,
                  cachedTopIndustry,
                  cachedSecondRole,
                  cachedTopLocation,
                }}
              />
            </TruncatableContent>
          </div>
          {userPendingApproval ? (
            <div className="flex items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold blur-none">
              <CityIcon size={40} style={{ marginRight: 20, fontSize: 70 }} />
              <div>Agency info shown here pending approval</div>
            </div>
          ) : (
            <LoginModal
              cta={(onClick) => (
                <div
                  onClick={onClick}
                  className="flex items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold cursor-pointer blur-none"
                >
                  <CityIcon
                    size={40}
                    style={{ marginRight: 20, fontSize: 70 }}
                  />
                  <div>
                    <span className="text-astral underline">Sign in</span> to
                    see information about this Recruiter
                  </div>
                </div>
              )}
              name={individual.attributes.publicName}
            />
          )}
        </div>
      ) : (
        <RecruiterStatisticsData
          {...{
            cachedTopRole,
            cachedTopIndustry,
            cachedSecondRole,
            cachedTopLocation,
          }}
        />
      )}
    </div>
  );
};

export default RecruiterStatistics;
