import React from "react";

import useSharedStyles from "../../show/useSharedStyles";

import ColorfulCityIcon from "../../../../General/Icons/ColorfulCityIcon";
import LocationIcon from "../../../../General/Icons/LocationIcon";
import PortfolioIcon from "../../../../General/Icons/PortfolioIcon";

const RecruiterStatisticsData = ({
  cachedTopRole,
  cachedTopIndustry,
  cachedSecondRole,
  cachedTopLocation,
}) => {
  const sharedClasses = useSharedStyles();
  const subHeader = sharedClasses.statisticSubHeader;

  return (
    <div style={{ textIndent: 25 }}>
      {cachedTopRole && (
        <div>
          <PortfolioIcon /> Placed{" "}
          <span className={subHeader}>
            {cachedTopRole[1]} candidate{cachedTopRole[1] > 1 ? "s" : ""}
          </span>{" "}
          in <span className={subHeader}>{cachedTopRole[0]}</span>
        </div>
      )}
      {cachedTopIndustry && (
        <div>
          <ColorfulCityIcon /> Placed{" "}
          <span className={subHeader}>
            {cachedTopIndustry[1]} candidate
            {cachedTopIndustry[1] > 1 ? "s" : ""}
          </span>{" "}
          in <span className={subHeader}>{cachedTopIndustry[0]}</span>
        </div>
      )}
      {cachedTopLocation && (
        <div>
          <LocationIcon /> Placed{" "}
          <span className={subHeader}>
            {cachedTopLocation[1]} candidate
            {cachedTopLocation[1] > 1 ? "s" : ""}
          </span>{" "}
          in <span className={subHeader}>{cachedTopLocation[0]}</span>
        </div>
      )}
      {cachedSecondRole && (
        <div>
          <PortfolioIcon /> Placed{" "}
          <span className={subHeader}>
            {cachedSecondRole[1]} candidate{cachedSecondRole[1] > 1 ? "s" : ""}
          </span>{" "}
          in <span className={subHeader}>{cachedSecondRole[0]}</span>
        </div>
      )}
    </div>
  );
};

export default RecruiterStatisticsData;
