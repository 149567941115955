import React, { useState } from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import makeStyles from "@material-ui/core/styles/makeStyles";

import withGlobalProviders from "../../../General/withGlobalProviders";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 50000, // must be larger than drawer zIndex
  },
  title: {
    borderBottom: "1px solid #EBEDF3",
    padding: "1.5rem 1.75rem",
    fontWeight: 500,
    fontSize: "1.75rem",
  },
  contentContainer: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      4
    )}px ${theme.spacing(3)}px`,
  },
  actions: {
    borderTop: "1px solid #EBEDF3",
    padding: "1.5rem",
    width: "100%",
    justifyContent: "center"
  },
}));

function LoginModal({ cta, name }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleToggleModal = () => {
    setOpen(!open);
  };

  return (
    <>
      {cta(handleToggleModal)}
      <Dialog
        open={open}
        onClose={handleToggleModal}
        classes={{ root: classes.root }}
        maxWidth={"lg"}
      >
        <DialogTitle classes={{ root: classes.title }}>Connect with Proven Agency Recruiters</DialogTitle>
        <DialogContent classes={{ root: classes.contentContainer }} style={{ paddingTop: 32 }}>
          <div className="flex flex-col items-center text-center">
            {name && <h2 className="text-2xl">
              {name} is a click away.
            </h2>}
            <p className="text-base my-3">
              You are close to full access to RecruiterDirect.
            </p>
            <a href="/join" className="inline-flex items-center justify-center px-3 py-2 border border-transparent text-lg uppercase font-normal rounded-md text-white bg-astral-500 hover:bg-astral-600">
              Join Now
            </a>
          </div>
        </DialogContent>
        {/* <DialogActions classes={{ root: classes.actions }}>
          Already a member?{" "}<a className="text-astral hover:text-astral ml-1" href="/sign-in">Sign In</a>
        </DialogActions> */}
      </Dialog>
    </>
  );
}

export default withGlobalProviders(LoginModal);
