import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import { styles } from "./utils";
import LoginModal from "../../shared/LoginModal";

const Input = ({ search, inputValue, setInputValue, loading, inputLoading }) => {
  const classes = styles({});

  const inLoadingState = (loading && search.length > 0) || inputLoading

  return (
    <LoginModal
      cta={(onClick) => (
        <div onClick={onClick}>
          <i className={`fas fa-search ${classes.inputIcon}`} />
          <input
            value={inputValue}
            placeholder="Sign in to search by Recruiter or Agency name"
            onChange={(e) => setInputValue(e.target.value)}
            disabled={true}
            className="form-control"
            style={{ paddingLeft: 36 }}
          />
          {!inLoadingState && search && search.length > 0 && (
            <i
              onClick={() => setInputValue("")}
              className={`fas fa-times ${classes.inputClose}`}
            />
          )}
          {inLoadingState && (
            <i className={`fas fa-spinner fa-spin ${classes.inputClose}`} />
          )}
        </div>
      )}
    />
  );
};

export default Input;
