import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useScreenClass } from "react-grid-system";
import makeStyles from "@material-ui/core/styles/makeStyles";

import useSharedStyles from "./useSharedStyles";
import Button from "../../Public/Individuals/shared/partials/Button";

import classnames from "classnames";
import get from "lodash/get";
import { phone_countries } from "./constants";

import { POST } from "../../../util/apiHelpers";


const useStyles = makeStyles((theme) => ({
  container: ({ isDesktop }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: isDesktop ? "flex-start" : "center",
  }),
  linkButtonRoot: ({ isDesktop }) => ({
    textTransform: "none",
    margin: isDesktop ? "8px 0" : theme.spacing(1),
    width: 200,
    fontSize: 16,
  }),
  linkDataContainer: {
    height: 40,
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1),
  },
}));

export const normalizePhoneNumber = (phoneNumber, defaultRes = "n/a") => {
  if (phoneNumber) {
    const normalized = phoneNumber
      .split("")
      .filter((elem) => elem.match(/\d/))
      .reverse()
      .slice(0, 10)
      .reverse()
      .join("");

    return `${normalized.slice(0, 3)}-${normalized.slice(
      3,
      6
    )}-${normalized.slice(6, 10)}`;
  } else {
    return defaultRes;
  }
};

const Link = ({ id, label, children, user_action }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const classes = useStyles({ isDesktop });
  const [isHidden, setHidden] = useState(true);
  const buttonContainerRef = useRef();

  const handleClick = () => {
    buttonContainerRef.current.addEventListener("animationend", () =>
      setHidden(false)
    );
    buttonContainerRef.current.classList.add("fadeOut");
  };

  return isHidden ? (
    <div ref={buttonContainerRef} className="animated">
      <Button
        onClick={handleClick}
        classes={{
          root: classes.linkButtonRoot,
        }}
      >
        {label}
      </Button>
    </div>
  ) : (
    <div className={classes.linkDataContainer}>{children}</div>
  );
};

const ContactLink = ({ id, attributes: { phone, phoneExt, phonePrefix, email } }) => {
  const prefixNumber = get(phone_countries, `${phonePrefix}.number`);

  const formattedPrefix = prefixNumber ? `+${prefixNumber}` : "";

  let formattedNumber = `${formattedPrefix} ${normalizePhoneNumber(phone)}`;

  phoneExt && (formattedNumber = formattedNumber + phoneExt);

  return (
    <Link label={"Contact Info"} {...{ id }} user_action={"contact_info"}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <i
            className="fa fa-phone"
            aria-hidden="true"
            style={{ marginRight: 8, marginBottom: 2 }}
          ></i>
          {formattedNumber}
        </div>
        <div>
          <i
            className="fa fa-envelope"
            aria-hidden="true"
            style={{ marginRight: 8, marginBottom: 2 }}
          ></i>
          {email}
        </div>
      </div>
    </Link>
  );
};

const ContactLinks = ({ individualId }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const classes = useStyles({ isDesktop });
  const sharedClasses = useSharedStyles({ isDesktop });

  const individual = useSelector(
    (state) => state.individuals.items[individualId]
  );

  return (
    <div className={classnames(classes.container, sharedClasses.rightSection)}>
      <ContactLink {...individual} />
    </div>
  );
};

export default ContactLinks;
