import makeStyles from "@material-ui/core/styles/makeStyles";

const useSharedStyles = makeStyles((theme) => ({
  sectionHeader: {
    fontWeight: 500,
    fontSize: 24,
    color: "#263238",
    lineHeight: 1.5,
  },
  section: ({ isDesktop }) => ({
    padding: isDesktop
      ? `${theme.spacing(2)}px 0px`
      : `${theme.spacing(0)}px 0px`,
    marginTop: 24,
  }),
  sectionContent: ({ isDesktop }) => ({
    width: "100%",
    padding: isDesktop
      ? `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
          1
        )}px ${theme.spacing(2)}px`
      : `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
          1
        )}px ${theme.spacing(0)}px`,
  }),
  subSectionHeader: {
    fontWeight: 500,
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.87)",
  },
  externalLink: {
    color: "#367BA6",
    "&:hover": {
      textDecoration: "none",
      color: "#367BA6",
    },
  },
  ratingWrapper: ({ isDesktop }) => ({
    display: "flex",
    flexDirection: isDesktop ? "row" : "column",
  }),
  overallRatingContainer: ({ isDesktop }) => ({
    padding: isDesktop ? `8px 16px 8px 0px` : 0,
    display: "flex",
    flexDirection: "column",
    alignItems: isDesktop ? "center" : "",
  }),
  ratingValueContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  ratingsValueNumber: {
    color: "#90a4ae",
    width: 50,
  },
  rightSection: ({ isDesktop }) => ({
    padding: isDesktop
      ? `${theme.spacing(1.5)}px ${theme.spacing(2)}px`
      : `${theme.spacing(1.5)}px 0px`,
  }),
  rightSectionHeader: {
    fontWeight: 600,
    color: "#48465B",
  },
  rightSectionContentItem: {
    marginBottom: theme.spacing(0.5),
  },
}));

export default useSharedStyles;
