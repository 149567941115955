import React from "react";
import { useSelector } from "react-redux";
import { useScreenClass } from "react-grid-system";

import makeStyles from "@material-ui/core/styles/makeStyles";

import useSharedStyles from "./useSharedStyles";
import TruncatableContent from "../../Public/Individuals/shared/partials/TruncatableContent";

import { prependHTTPProtocol } from "../../../util/formatHelpers";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  avatarContainer: ({ isDesktop }) => ({
    marginRight: theme.spacing(2),
    width: 135,
    height: 60,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }),
  avatarImage: {
    flexShrink: 0,
    maxWidth: "100%",
    maxHeight: "100%",
  },
  headerTextContainer: {
    display: "flex",
    flexDirection: "column",
    height: 60,
  },
}));

const Header = ({ name, avatarIcon, url, teamSize }) => {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const classes = useStyles({ isDesktop });
  const sharedClasses = useSharedStyles();

  return (
    <div className={classes.headerContainer}>
      <div className={classes.avatarContainer}>
        <img className={classes.avatarImage} src={avatarIcon} alt={name} />
      </div>
      <div className={classes.headerTextContainer}>
        {url ? (
          <a
            href={prependHTTPProtocol(url)}
            rel="noopener noreferrer"
            target="_blank"
            className={sharedClasses.externalLink}
            style={{ marginTop: 4, color: "#357BA6" }}
          >
            <h5 className={sharedClasses.subSectionHeader}>
              {name} <i className={`icon-xl la la-external-link`}></i>
            </h5>
          </a>
        ) : (
          <h5 className={sharedClasses.subSectionHeader}>{name}</h5>
        )}
        {teamSize && teamSize > 1 && `${teamSize} team members on RecruitiFi`}
      </div>
    </div>
  );
};

const AgencyDescription = ({ individualId }) => {
  const individual = useSelector(
    (state) => state.individuals.items[individualId]
  );
  const organization = useSelector(
    (state) => state.organizations.items[individual.attributes.organizationId]
  );
  const sharedClasses = useSharedStyles();

  return (
    <div className={sharedClasses.section}>
      <div className={sharedClasses.sectionHeader}>About the Agency</div>
      <div className={sharedClasses.sectionContent}>
        <Header {...organization.attributes} />
        <TruncatableContent truncateHeight={300}>
          {organization.attributes.organizationDescription}
        </TruncatableContent>
      </div>
    </div>
  );
};

export default AgencyDescription;
