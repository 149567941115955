import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ButtonBase from "@material-ui/core/ButtonBase";

import withGlobalProviders from "../../../General/withGlobalProviders";
import OptimizedRatesIcon from "../../../General/Icons/OptimizedRatesIcon";
import LoginModal from "./LoginModal";

const useStyles = makeStyles(() => ({
  caret: {
    "& .caret": {
      transition: "transform .2s ease-in-out",
    },
    "& .caret-open": {
      transform: "rotate(180deg)",
    },
  },
}));

function RecruiterCart({ individualName }) {
  const classes = useStyles();

  return (
    <LoginModal cta={
      (onClick) => (
        <ButtonBase
          style={{
            height: 37,
            width: 240,
            backgroundColor: "#fff",
            color: "#333",
            "&:hover": {
              color: "#333",
            },
          }}
          onClick={onClick}
          className={`btn btn-brand-secondary ${classes.caret}`}
        >
          <OptimizedRatesIcon
            style={{ marginRight: 4, marginTop: -2, marginLeft: -2 }}
          />
          0 Recruiters Saved
          <ExpandMoreIcon className="caret" />
        </ButtonBase>
      )
    }
      name={individualName}
    />
  );
}

export default withGlobalProviders(RecruiterCart);
