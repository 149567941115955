import React from "react";
import { Disclosure, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import map from "lodash/map";

const questions_and_answers = [
  {
    question: "What types of agencies do the recruiters in the directory work for?",
    answer: "We have agencies from all sectors of the market. The largest full-service recruiting agencies in the world like Randstad and ManpowerGroup are represented, as well as highly specialized boutique recruiting firms that focus on specific industries. Quickly search and filter recruiting agencies based on the role, industry, and geography to find the right specialized agency recruiters from different firms of all shapes and sizes."
  },
  {
    question: "Is the recruiting agency directory for full-time permanent placement roles or contingent, temp, staffing, and contract roles?",
    answer: "Both! The recruiters will note on their profile whether they only work on full time direct roles or whether they can also contribute candidates in a temp staffing, contingency workforce, or contract setting."
  },
  {
    question: "For full time roles, are these contingent agencies or retained executive search firms?",
    answer: "Both! Whether you're looking for senior executive roles and want to hire a high end retained search firm, or you'd rather hedge your bets and work with a recruiting firm on a contingent basis, you will be able to filter for that information on their profiles."
  },
  {
    question: "What about diversity agencies?",
    answer: "We have many agencies that specialize in finding diversity candidates. We also have many agencies that are classified as diversity suppliers. You can filter based on companies that are certified as a Minority and Women-Owned Business Enterprise (MWBE), Veteran-Owned Small Businesses (VOSB), and many others."
  },
  {
    question: "What type of industries are represented?",
    answer: "What's your industry? We've got that covered! This is not an exhaustive list, but a few of our more popular industries include: Accounting, Advertising, Airlines/ Aviation, Banking, Biotech, Construction, Consulting, Defense, Electronics, Entertainment, Financial Services, Food & Beverages, Hospital & Health Care, Hospitality, Information Technology, Insurance, Legal Services, Leisure, Travel & Tourism, Manufacturing, Marketing and Advertising, Mechanical or Industrial Engineering, Media Production, Medical Devices, Oil & Energy, Pharmaceuticals, Public Relations and Communications, Real Estate, Retail, Tech, Telecommunications\nTransportation\nTrucking / Logistics"
  },
  {
    question: "What about geographic recruiting coverage?",
    answer: "The recruiting directory provides recruiting coverage for 50 states and Canada. Also keep an eye out for international recruiting coverage, as we are quickly building up our recruiter listings in other regions to help you recruit in EMEA, APAC, and LATAM."
  },
  {
    question: "Can I hire recruiters as recruiting consultants on a temporary contract basis to augment my TA team?",
    answer: "That' was not our intention in creating the agency directory. It was designed to help employers seek out third-party recruiting agencies. That being said, if you inquire directly with the recruiters, you'll find that they offer a variety of hiring services, from filling in as a contract TA consultant to providing temporary RPO services."
  },
  {
    question: "What's the difference between an agency recruiter and a headhunter?",
    answer: "Great question. The answer is--nobody knows! Ask ten different people in the recruiting industry, and you'll get ten different answers. Many will call them specialized agency recruiters, headhunters, third-party talent consultants. Different names have gone in and out of fashion, but the concept is relatively unchanged since World War II, when workers were called off to the war effort, and staffing agencies were created to fill the gaps in the workplace."
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function FrequentlyAskedQuestion({ question, answer }) {
  return (
    <Disclosure as="div" className="pt-3">
      {({ open }) => (
        <>
          <div className="text-lg">
            <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400 border-b pb-3 border-gray-600">
              <span className="text-2xl font-medium text-gray-600">{question}</span>
              <span className="ml-6 h-7 flex items-center">
                <ChevronDownIcon
                  className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </div>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel as="dd" className="mt-2 pr-12">
              <p className="text-2xl text-gray-600 mt-3">
                {answer}
              </p>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}

export default function FrequentlyAskedQuestions() {
  return (
    <div className="bg-white pt-12 pb-24">
      <div className="kt-container">
        <div className="w-full">
          <h2 className="text-4xl tracking-wide font-medium text-gray-800 sm:text-4xl mb-3">
            Frequently Asked Questions
          </h2>
          <div className="mt-6 grid grid-cols-1 gap-12">
            {map(questions_and_answers, (questions_and_answer, idx) => <FrequentlyAskedQuestion key={idx} {...questions_and_answer} />)}
          </div>
        </div>
      </div>
    </div>
  )
}
