import React, { useCallback, useEffect, useRef, useState } from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";

import get from "lodash/get";

const GRADIENT_HEIGHT = 80;
const EXPAND_TEXT_HEIGHT = 24;

const useStyles = makeStyles(() => ({
  container: ({ hasContentOverflow, truncated, truncateHeight }) => ({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    maxHeight: hasContentOverflow && truncated ? truncateHeight : "none",
  }),
  childrenContainer: ({ hasContentOverflow, truncated }) =>
    hasContentOverflow && truncated
      ? {
          overflowY: "hidden",
        }
      : {},
  gradientContainer: ({ hasContentOverflow, truncated, backgroundColor }) => ({
    display: hasContentOverflow && truncated ? "inherit" : "none",
    backgroundImage:
      hasContentOverflow && truncated
        ? `linear-gradient(to bottom, transparent, ${backgroundColor})`
        : "none",
    height: GRADIENT_HEIGHT,
    position: "absolute",
    bottom: 0,
    width: "100%",
  }),
  truncateToggleText: ({ hasContentOverflow }) => ({
    display: hasContentOverflow ? "inherit" : "none",
    height: EXPAND_TEXT_HEIGHT,
    color: "#f25b5e",
    cursor: "pointer",
    zIndex: 1,
    position: "absolute",
    bottom: -EXPAND_TEXT_HEIGHT,
    alignSelf: "center",
  }),
}));

const TruncatableContent = ({
  truncateHeight,
  children,
  expandText = "See More",
  truncateText = "See Less",
  backgroundColor = "#ffffff",
}) => {
  const childrenContainer = useRef(null);

  const [hasContentOverflow, setHasContentOverflow] = useState(false);
  const [truncated, setTruncated] = useState(false);

  const evaluateContentOverflow = useCallback(() => {
    const contentHeight = get(childrenContainer, "current.scrollHeight");

    setHasContentOverflow(contentHeight > truncateHeight);
  }, [childrenContainer, truncateHeight]);

  useEffect(() => {
    evaluateContentOverflow();

    window.addEventListener("resize", evaluateContentOverflow);

    return () => {
      window.removeEventListener("resize", evaluateContentOverflow);
    };
  }, [evaluateContentOverflow]);

  useEffect(() => {
    setTruncated(hasContentOverflow);
  }, [hasContentOverflow]);

  const classes = useStyles({
    hasContentOverflow,
    truncateHeight,
    truncated,
    backgroundColor,
  });

  return (
    <div className={classes.container}>
      <div ref={childrenContainer} className={classes.childrenContainer}>
        {children}
      </div>
      <div className={classes.gradientContainer}></div>
      <div
        className={classes.truncateToggleText}
        onClick={() => setTruncated(!truncated)}
      >
        {truncated ? expandText : truncateText}
      </div>
    </div>
  );
};

export default TruncatableContent;
