import { combineReducers } from "redux";
import uiReducer from "./ui/uiReducer";
import individualsReducer from "./individualsReducer";
import organizationsReducer from "./organizationsReducer";

export default combineReducers({
  individuals: individualsReducer,
  organizations: organizationsReducer,
  ui: uiReducer,
});
